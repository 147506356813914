@use 'src/Variable/_variable.scss' as colors;
@use 'src/styles/z_index.scss' as zIndexSize;

.comment {
  &:global(.pointer):hover {
    background-color: colors.$cloudyBlue;
  }

  .comment-content-block {
    flex: 1 0 auto;
    max-width: calc(100% - 30px);
  }

  &.is-saving,
  &.is-removing {
    position: relative;

    &:after {
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -5px;
      z-index: zIndexSize.$third-layer;
      background-color: rgba(colors.$boulder, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      border-radius: 5px;
    }
  }

  &.is-saving:after{
    content: 'Saving ...';
  }
  &.is-removing:after{
    content: 'Removing ...';
  }
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.action-button {
  height: auto;
  :hover {
    color: colors.$easternBlue;
  }
}

$pin-parker-width: 15px;

.pin-marker {
  width: $pin-parker-width;
  height: $pin-parker-width;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: -3px;

  position: absolute;
  top: 2px;

  path {
    stroke: colors.$pacificBlueColor;
  }
}

.comment-text-wrapper {
  position: relative;
}

.comment-text {
  p {
    margin: 0;
  }

  img {
    max-width: 100% !important;
    border-radius: 8px !important;
  }

  &.with-icon,
  &.with-icon > *:first-child {
    text-indent: $pin-parker-width + 7px;
  }

  :global(.mention) {
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: colors.$easternBlue;
    font-weight: 600;
  }
}
.action-dropdown {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.image-menu {
  box-shadow: 0 0 2px rgba(40, 41, 61, 0.04), 0 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  :global(.ant-dropdown-menu-item) {
    &:hover {
      background: colors.$addProjectOptionBgColor !important;
    }
  }
}

.dots-icon {
  display: none;
  position: absolute;
  right: 12px;
  bottom: 12px;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 20px;
  cursor: pointer;
  background: colors.$catskillWhite;
  border: 1px solid colors.$greenWhiteColor;
  border-radius: 40px;
  text-align: center;
  &:hover {
    background: colors.$whiteColor;
  }
  img {
    height: 15px;
    width: 5px;
  }
}

.menu-button {
  width: 100%;
  background-color: transparent;
  padding: 3px 4px 3px 0;
  gap: 12px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .menu-icon {
    height: 16px;
    width: 16px;
  }

  .menu-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: colors.$projectThumbnailColor
  }
}

.danger-color {
  color: colors.$fuzzyWuzzyBrown;

  .menu-text {
    color: colors.$fuzzyWuzzyBrown;
  }
}

.image-container {
  position: relative;
  &:hover {
    .dots-icon {
      display: flex !important;
    }
  }
}

.image {
  max-width: 100%;
  margin: 0 auto;
  display: block;
  border-radius: 8px;
}

.comment-user-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.comment-time {
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: colors.$carbonGrey;
}
