@use 'Variable/_variable.scss' as colors;

.modal {
  border-radius: 16px;
  overflow: hidden;

  :global(.ant-modal-body) {
    padding: 0;
    height: 100%;
  }
  :global(.ant-modal-header) {
    border-bottom: none;
    padding: 0;
  }
  :global(.ant-modal-content) {
    padding: 0;
  }
  :global(.ant-modal-title) {
    line-height: 35px;
  }
}

.react-quill-text {
  font-size: 18px;
  line-height: 20px;
  color: colors.$carbonGrey;
  word-break: break-word;

  p {
    margin: 0;
  }
}

.moodboard-text-react-quill {
  $border-radius: 8px;

  :global(.ql-toolbar),
  :global(.ql-container),
  :global(.ql-editor) {
    padding: 0;
  }

  :global(.ql-toolbar) {
    border-radius: $border-radius $border-radius 0 0;
    padding: 16px 12px;
  }

  :global(.ql-container) {
    border-radius: 0 0 $border-radius $border-radius;
    overflow: auto;

    @extend .react-quill-text;

    :global(.ql-editor) {
      padding: 16px;
      min-height: 240px;
      max-height: calc(100vh - 400px);
    }

    :global(.ql-editor)::before {
      font-style: normal;
      font-size: 18px;
      color: colors.$carbonGrey;
      opacity: 0.3;
    }
  }
}
