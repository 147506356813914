@use 'src/Variable' as styles;

.container {
  width: 100%;
  border-top: 2px solid styles.$dawnPink;
}

.input__container {
  :global(.ant-select-selector) {
    height: 40px !important;
  }
  :global(.ant-select-selection-placeholder) {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 40px !important;
    letter-spacing: 0.5px !important;
    color: styles.$figmaInputColor !important;
  }
  :global(.ant-select-selection-item) {
    display: flex !important;
    align-items: center !important;
  }
}

.section__heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.25px;
  color: styles.$projectThumbnailColor;
}

.section__description {
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  opacity: 0.6;
}

.progress {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  opacity: 0.6;
}

.buttons__section {
  border-top: 1px solid styles.$dawnPink;
}
