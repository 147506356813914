@use 'Variable/_variable.scss' as colors;

.field {
  :global(.ant-col), :global(.ant-form-item-control-input) {
    position: initial;
  }
  &:global(.ant-form-item) {
    vertical-align: initial;
  }

  display: inline-block;
  border: 1px solid colors.$lavenderPinocchio;
  padding: 4px 8px;
  border-radius: 4px;
  line-height: 20px !important;

  &:focus-within {
    border-color: colors.$easternBlue;
  }

  &:global(.ant-form-item-has-error) {
    border-color: colors.$errorColor;
  }
}

.underline {
  border-bottom: 2px solid colors.$pacificBlueColor;
  display: inline-block;
  position: relative;
  top: -0.18em;
  word-break: break-word;
}

.tag {
  font-size: 0.7em !important;
  background: colors.$desertStormColor;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  display: inline-flex;
  align-items: center;
  position: relative;
  top: 1px;
  white-space: break-spaces;
  word-break: break-word;

  :global(.ant-tag-close-icon) svg {
    width: 9px;
    height: 9px;
    margin-left: 5px;
    position: relative;
    top: -3px;
  }

  .color-tag {
    width: 10px;
    height: 10px;
    display: inline-block !important;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}

.input-wrapper {
  position: relative;
}

.sketch-picker {
  position: absolute;
  top: 110%;
  left: 0px;
  z-index: 100;
  display: none;
}

.input:focus + .sketch-picker,
.sketch-picker:hover {
  display: block;
}
