@use './variable' as *;
@use 'styles/z_index.scss' as zIndexSize;

.primaryNewDesignButton {
  background-color: $primaryDashboardButtonColor;
  text-transform: uppercase;
  border-radius: 12px;
  padding: 10px 20px;
  letter-spacing: 1.25px;
  color: $whiteColor;
  border: none;
  cursor: pointer;
}

.secondaryNewDesignButton {
  color: $secondaryDashboardButtonColor;
  border: 1px solid $secondaryDashboardButtonColor;
  background-color: transparent;
  text-align: center;
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1.25px;
  border-radius: 8px;
  cursor: pointer;
}

.primaryWorkspaceButtonDesign {
  background-color: $primaryWorkspaceButtonColor;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  padding: 12px 12px;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  color: $whiteColor;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.transparentWorkspaceButtonDesign {
  background-color: transparent;
  border: none;
  border-radius: 12px;
  padding: 10px 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $secondaryDashboardButtonColor;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
}

.feedHeadingDesign {
  margin-left: 40px;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  letter-spacing: 0.25px;
  line-height: 30px;
  color: $notesTextColor;
}

.feedMilestoneContainerDesign {
  flex: 0.65;
  margin-left: 40px;
  margin-bottom: 32px;
  margin-top: 36px;
  background-color: $notesBackgroundColor;
  border-radius: 12px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  width: 58rem;
}

.addProjectOptionNumberDesign {
  color: $whiteColor;
  padding: 2px 8px;
  line-height: 19px;
  font-size: 12px;
  font-weight: 600;
  background-color: $addProjectOptionNumberBgColor;
  border-radius: 50%;
}

.milestoneStepNumberDesign {
  color: $milestoneStepTextColor;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 1.25px;
}

.modalTimelineScreenDesign {
  width: 100%;
  height: 100%;
  margin-bottom: 90px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.workspaceThumbnailTitleStyle {
  font-size: 10px;
  text-transform: uppercase;
  line-height: 13px;
  letter-spacing: 1.5px;
}

.workspaceThumbnailSubTitleStyle {
  color: $blackColor;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.workspaceThumbnailButtonStyle {
  border-radius: 8px;
  padding: 6px 16px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1.25px;
}

.thumbnailHeadingStyles {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.thumbnailSubHeadingStyles {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.thumbnailTextStyles {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: $secondaryDashboardButtonColor;
}

.menuOptionStyles {
  width: 100%;
  text-align: center;
  button {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 24px;
  }
}

// Workspace (Presentation + Deliver Files) styles
.milestoneHeaderStyles {
  margin: 0px 24px;
  padding: 20px 20px 0px;
  height: 100%;
  border: 2px solid $workspaceThumbnailBorderColor;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
}

.milestoneThreeDotsStyles {
  border: none;
  background-color: transparent;
  img {
    margin-bottom: 4px;
    margin: 0px 8px;
  }
}

.milestoneUploadButtonStyles {
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.milestoneSystemUploadStyles {
  margin: 10px 0px;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    color: $notificationDateColor;
  }
}

.milestoneThumbnailLayout {
  position: relative;
  margin: 8px 24px 24px 12px;
  margin-top: 0px;
  padding: 24px;
  border: 2px solid $workspaceThumbnailBorderColor;
  border-radius: 12px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
}

.milestoneThumbnailHeaderStyles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -12px 0px;
  height: 60px;
}

.milestoneThumbnailImageStyles {
  img {
    margin-right: 13px;
    width: 26px;
    height: 26px;
  }
}

.milestoneThumbnailMenuImageStyles {
  margin-left: 16px;
  margin-bottom: 3px;
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 4px;
    img {
      margin: 0px 6px;
    }
  }
}

.milestoneThumbnailMenuStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -40px;
  width: 72px;
  height: 36px;
  border: 2px solid $replyTextColor;
  z-index: zIndexSize.$second-layer;
}

.milestoneThumbnailMenuOptionStyles {
  button {
    font-size: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    border: none;
    height: 24px;
    margin-right: -8px;
    margin-top: 8px;
  }
}

.milestoneThumbnailBodyStyles {
  margin-top: 0px;
  margin-right: -10px;
  min-height: calc(540px - 140px);
  max-height: 100%;
  position: relative;
  margin-bottom: 40px;
}

.milestoneThumbnailTilesLayout {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.milestoneThumbnailViewOneSliderStyles {
  background-color: $milestoneStepTextColor;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: -52px;
  color: $whiteColor;
  padding: 8px 8px;
  border-radius: 8px;

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 0px 8px;
    img {
      height: 20px;
    }
  }
}

.milestoneThumbnailViewAllSliderStyles {
  background-color: $milestoneStepTextColor;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: -48px;
  left: 42%;
  color: $whiteColor;
  padding: 8px 8px;
  border-radius: 8px;
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 0px 8px;
    img {
      height: 20px;
    }
  }
}

.milestoneThumbnailButtonPosition {
  position: absolute;
  right: 8px;
  bottom: 16px;
  padding: 10px 20px;
  margin: 0px 16px;
  span {
    margin-left: 4px;
  }
}

.figmaLinkUploadContainerStyles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 60px;
}

.figmaLinkInputStyles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid $notificationDateColor;
  border-radius: 4px;
  input {
    color: $figmaInputColor;
    flex: 1;
  }
  input::placeholder {
    color: $figmaInputColor;
  }
  img {
    height: 24px;
    width: 24px;
  }
}

.updateThumbnailSectionStyles {
  border: 1px solid $notificationDateColor;
  position: relative;
  margin: 0px 26px;
  margin-top: 16px;
  margin-bottom: 48px;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
}
