@use 'Variable/_variable.scss' as colors;

.discovery-alignment-form {
  &[disabled] {
    pointer-events: none;
  }
}

.question-group-edit-buttons-wrapper {
  display: none;
}

.show-question-group-edit-buttons-wrapper:not(:global(.hide)),
.question-group-wrapper:hover .question-group-edit-buttons-wrapper:not(:global(.hide)) {
  display: flex;
}

.question-group p {
  display: inline;
}

.question-group-wrapper {
  &:hover .question-max-width {
    max-width: calc(100% - 300px);
  }
}

.question-max-width {
  max-width: 100%;
}

.edit-button {
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border-color: colors.$pacificBlueColor;
  }

  color: colors.$pacificBlueColor;

  &:hover {
    background-color: colors.$pacificBlueColor;
    color: colors.$whiteColor;
    svg path {
      fill: colors.$whiteColor;
    }
  }

  &.shape-edit-button {
    border-radius: 100%;
  }
}

$selected-question-padding-y: 8px;
$selected-question-padding-x: 16px;
$selected-question-border-size: 1px;

.border {
  border: $selected-question-border-size solid colors.$lavenderPinocchio;
  border-radius: 8px;
  margin: (
    (-$selected-question-padding-y - $selected-question-border-size)
    0
    (-$selected-question-padding-y - $selected-question-border-size)
    (-$selected-question-padding-x - $selected-question-border-size)
  );
}

.selected-question {
  background: colors.$whiteColor;
  padding: $selected-question-padding-y $selected-question-padding-x;
}

.market-question-list-item {
  margin-left: 40px;
  position: relative;

  &:before {
    content: '•';
    position: absolute;
    left: -30px;
  }
}

.numeric-question-list-item {
  margin-left: 40px;
  position: relative;
  counter-increment: section;

  &:before {
    content: counter(section) '.';
    position: absolute;
    left: -30px;
  }
}

.reset-numeric-list {
  counter-reset: section 0;
}
