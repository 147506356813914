@use 'src/Variable/index' as styles;
@use 'src/styles/screen_size.scss' as screenSize;
@use 'src/styles/rem.scss' as util;

$header-navigation-height: 56px;

.project__detailsHeader {
  @include styles.flexDisplay(flex, space-between, center);
  padding: util.px-to-rem(15px) util.px-to-rem(46px) util.px-to-rem(15px) util.px-to-rem(24px);
  background: styles.$linkWaterColor;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  height: $header-navigation-height;
}

.project__detailsLeft {
  @include styles.flexDisplay(flex, flex-start, center);
}

.project__detailsRight {
  @include styles.flexDisplay(flex, flex-end, center);
  gap: util.px-to-rem(14);
  flex-wrap: wrap;
}

.project-tab {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.25px;
  padding: 4px 8px;
  border-radius: 8px;
  color: styles.$nevada !important;
  display: flex;
  align-items: center;

  svg {
   color: styles.$silverChalice !important;
  }

  
  &.project-tab-active,
  &:hover {
    color: styles.$blackColor !important;

    svg {
      color: styles.$pacificBlueColor !important;
    }
  }
}

.link {
  color: styles.$blackColor;
  &:hover {
    color: styles.$pacificBlueColor !important;
  }
}

.project__navHeading {
  margin: 0;
  margin-right: 58px;
  max-width: 40vw;
  font-weight: 400;
  font-size: util.px-to-rem(20px);
  line-height: 28px;
  letter-spacing: 0.25px;
}

.navbar__avatars {
  display: flex;
  align-items: center;
  margin-left: 10px;
  .navbar__avatar {
    position: relative;
    background: styles.$whiteColor;
    border: 2px solid styles.$whiteColor;
    filter: drop-shadow(0px 3.10107px 6.97741px rgba(0, 0, 0, 0.09));
    border-radius: 50%;
    overflow: hidden;
    height: 36px;
    width: 36px;
    display: block;
    margin-left: -16px;
  }
}

.back__arrow {
  margin-right: 1.2rem;
  color: styles.$easternBlue;
}

.back__tab {
  white-space: nowrap;
}

.navbar__addButton {
  border: 1px solid styles.$secondaryDashboardButtonColor;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  background-color: transparent;
  img {
    @include styles.flexDisplay(flex, center, center);
    width: 16px;
    height: 16px;
  }
}

.navbar__addButton {
  margin-left: 8px;
}
@media only screen and (max-width: screenSize.$screen-md) {
  .project__detailsRight {
    gap: 0;
  }
  .navbar__avatars {
    .workspace__avatar {
      height: 2rem;
    }
  }
  .navbar__avatars {
    .navbar__avatar {
      height: 1.8rem;
    }
  }

  .workspace__notificationButton,
  .workspace__disableNotificationButton,
  .workspace__addButton {
    img {
      width: 15px;
      height: 15px;
    }
  }
}

.tab__active,
.tab__inactive {
  border-bottom: 3px solid;
}
.tab__active {
  color: styles.$easternBlue;
  border-bottom-color: styles.$easternBlue;
}
.tab__inactive {
  color: styles.$notesTextColor;
  border-bottom-color: styles.$concreteColor;
}

.tab__button {
  @extend .buttonStyle;
  padding: 0 12px 14px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  color: styles.$notesTextColor;
  margin-right: 12px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

@media only screen and (max-width: screenSize.$screen-xs) {
  .tab__button {
    padding: 0 12px 0 0;
    font-size: 10px;
    margin-right: 8px;
  }
}
