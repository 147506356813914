@use 'src/Variable' as colors;

.milestone__title {
  font-weight: 500;
  font-size: 42px;
  line-height: 40px;
  letter-spacing: 0.15px;
}

.colorPalette__listWrapper {
  gap: 20px;
}
