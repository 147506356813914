@use 'Variable/_variable.scss' as colors;

.radio {
  :global(.ant-radio-wrapper) {
    margin-right: 20px;
    & > span {
      font-size: 16px !important;
      line-height: 24px;
      letter-spacing: 0.5px;
      padding-left: 15px;
      padding-right: 0;
    }
    span:global(.ant-radio) {
      padding: 0;
      margin: 0;

      & + span {
        white-space: break-spaces;
      }
    }

    :global(.ant-radio-checked .ant-radio-inner) {
      border-color: colors.$pacificBlueColor;

      &:after {
        background-color: colors.$pacificBlueColor;
      }
    }

    :global(.ant-radio-disabled) + span {
      color: inherit !important;
    }
  }
}

.radio-with-bg {
  display: flex;
  flex-direction: column;
  width: 200px;
  background: colors.$linkWaterColor;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  overflow: hidden;

  .radio-bg {
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 110px;
  }
}
