@use 'src/Variable' as styles;

.empty-kanban-board {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .sync-comments-title {
    width: 569px;
    color: styles.$blackColor;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .sync-comments-description {
    width: 377px;
    color: styles.$blackColor;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    opacity: 0.5;
  }
}

.manage-files-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  margin-bottom: 21px;
  height: 36px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
