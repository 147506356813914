@use 'Variable/_variable.scss' as colors;

.vote-tools-wrapper {
  padding: 10px 15px;
  background-color: rgba(colors.$blackColor, 0.65);
  border-radius: 50px;
  border: none;
  transition: all 0.3s;

  &:hover:not([disabled]) {
    background: rgba(0, 0, 0, 0.85);
  }

  &[disabled] {
    cursor: auto;
  }

  :global(.ant-spin) {
    width: 18px;
  }
  :global(.ant-spin-dot-item) {
    background-color: colors.$whiteColor;
  }
}
