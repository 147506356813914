@use 'src/Variable' as colors;

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .image {
    margin: 12px 0px;
    width: 100%;
    max-width: 350px;
  }
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
  letter-spacing: 0.16px;
}

.form {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;

  .input-container {
    width: 100%;
    .input {
      width: 100%;
      display: flex;
      height: 32px;
      padding: 0px 8px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 6px;
      border: 1px solid colors.$silverChalice;
      &:hover,
      &:focus {
        outline: none;
        border: 1px solid colors.$pacificBlueColor !important;
        border-left: unset;
        box-shadow: none;
      }

      ::placeholder {
        color: colors.$silverDark;
        font-size: 11px;
        font-weight: 400;
        line-height: 14px; /* 127.273% */
        letter-spacing: 0.11px;
      }

      img {
        width: 30px;
        padding-left: 0px;
      }
    }
  }

  .submit-button-container {
    display: flex;
    justify-content: flex-end;

    .button {
      display: flex;
      padding: 8px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 4px;
      color: colors.$whiteColor;
      text-align: center;
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px; /* 116.667% */
      letter-spacing: 0.12px;
      text-transform: uppercase;
    }
    .disabled-button {
      background: colors.$silverChalice !important;
      cursor: not-allowed !important;
    }
  }
}
