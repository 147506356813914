@use 'Variable/_variable.scss' as colors;

.paragraph {
  line-height: 32px;
}

.connected-with-next {
  margin-right: 0.3em;
}

.new-question-text-field {
  &:not(:global(.ant-form-item-has-error)) span[data-placeholder]:before {
    color: colors.$notesTextColor;
  }
}

.border {
  border: 1px solid rgba(48, 140, 143, 0.08);
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
}

.selected-question {
  display: flex !important;
  background: colors.$whiteColor;
  padding: 16px;
}

.add-question-button {
  background: colors.$forestGreen;
  color: colors.$whiteColor;

  &:hover {
    border-color: colors.$forestGreen;
    color: colors.$forestGreen;
  }
}

.edit-question {
  display: flex !important;
  justify-content: space-between;
  margin: 0;

  :global(.ant-form-item-control-input) {
    cursor: text;
  }
}
