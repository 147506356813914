@use 'src/Variable' as styles;

.reply-container {
  padding: 12px;
  gap: 8px;
  background: styles.$paleGrey;
  border: 1px solid styles.$primaryButtonBorderColor;
  border-radius: 8px;
}

.user-name-text {
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.posted-time-text {
  margin-left: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: styles.$carbonGrey;
}

.container-text {
  color: styles.$carbonGrey;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
  display: flex;
}

.reply-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  white-space: break-spaces;
}

.quote-timestamp {
  margin-left: auto !important;
  margin-right: 12px;
}
