@use 'Variable/_variable.scss' as colors;

.add-question-button {
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border-color: colors.$pacificBlueColor;
  }

  color: colors.$pacificBlueColor;

  &:hover {
    background-color: colors.$pacificBlueColor;
    color: colors.$whiteColor;
    svg path {
      fill: colors.$whiteColor;
    }
  }
}
