@use 'Variable/_variable.scss' as colors;

.suggestions-list-wrapper {
  height: 100%;
  overflow: auto;
}

.suggestion {
  padding: 16px;
  background: colors.$twilightBlue;
  border: 1px solid colors.$easternBlue;
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  color: colors.$blackColor;
  margin: 12px 0 0;
}
