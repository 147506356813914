@use 'src/Variable/index' as styles;

.nav {
  display: flex;
  padding: 20px 32px;
  background-color: styles.$whiteColor;
  align-items: center;
  justify-content: center;

  .nav-inner {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      height: 25px;
      width: auto;
    }

    .user-details {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .user-avatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      overflow: hidden;
      display: block;
    }

    p {
      margin: 0px;
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;
    }
  }
}