@use 'Variable/_variable.scss' as colors;

.add-figma-file-modal {
  background: colors.$linkWaterColor;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 12px;

  :global(.ant-input-prefix) {
    padding: 0 5px !important;
  }
  :global(.ant-modal-content),
  :global(.ant-modal-header) {
    background: transparent;
    padding: 0;
  }
}

.step-wrapper {
  overflow: hidden;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  min-height: min(600px, calc(100vh - 140px));
}

.comment-form-wrapper {
  margin: 50px auto 0 auto;
  width: 80% !important;
}

.thumbtack-icon path {
  stroke: colors.$easternBlue;
}
