@use 'src/Variable' as styles;

.overlay_wrapper {
  background: styles.$linkWaterColor;
  box-shadow: 0 0 2px rgba(40, 41, 61, 0.04), 0 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 4px;

  overflow: hidden;

  :global(.ant-dropdown-menu) {
    padding: 0 !important;
  }

  :global(.ant-dropdown-menu-item-active) {
    background: styles.$catskillWhite !important;
  }
}
