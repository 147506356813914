@use 'src/styles/z_index.scss' as zIndexSize;

.full-screen {
  height: 100%;
}

.vote-tools {
  position: absolute;
  z-index: zIndexSize.$third-layer;
  top: 16px;
  left: 20px;
}
