@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Whisper&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Actor&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Outfit', sans-serif;
}

.tooltip-container .ant-tooltip-content .ant-tooltip-arrow .ant-tooltip-arrow-content {
  --antd-arrow-background-color: #101828;
}
.tooltip-container .ant-tooltip-content .ant-tooltip-inner {
  padding: 8px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #FFFFFF;
  background: #101828;
  border-radius: 8px;

  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

div#loom-sdk-record-overlay-shadow-root-id {
  display: block !important;
}
