@use 'styles/rem.scss' as *;
@use 'src/Pages/ProjectDetails/components/ProjectDetailsHeader/styles.module.scss' as projectDetailsHeader;

.project__workspaceWrapper {
  display: flex;
  overflow: hidden;
  height: calc(100vh - px-to-rem(projectDetailsHeader.$header-navigation-height));
}

.workspace__sidebar__wrapper {
  flex-basis: 265px;
}

.workspace__content__wrapper {
  flex: 1 1 90%;
  border-left: 2px solid rgba(0, 0, 0, 0.08);
  overflow: auto;
  height: 100%;
  padding-top: 1px; // for element shadows inside
  transition: all 0.3s;
}
