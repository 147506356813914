.flex {
  display: flex;
}
.flex-inline {
  display: inline-flex;
}

.flex-grow {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-shrink {
  flex-shrink: 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.align-stretch {
  align-items: stretch;
}
.align-base {
  align-items: baseline;
}

.just-start {
  justify-content: flex-start;
}
.just-center {
  justify-content: center;
}
.just-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}

.flex-component-center {
  @extend .align-center, .just-center;
}

$align-self: (
  align-self-auto: auto,
  align-self-start: flex-start,
  align-self-end: flex-end,
  align-self-center: center,
  align-self-baseline: baseline,
  align-self-stretch: stretch,
);

@each $className, $flexRule in $align-self {
  .#{$className} {
    align-self: $flexRule;
  }
}

@for $i from 1 through 100 {
  .gap-#{$i} {
    gap: #{$i}px;
  }
}
