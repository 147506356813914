@use 'src/Variable' as styles;

.labels-dropdown-container {
  display: flex;
  z-index: 100;
  min-width: 320px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 4px;
  background: styles.$whiteColor;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04);

  .input-container {
    display: flex;
    background: styles.$alabaster;
    padding: 8px 10px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    width: 100%;
    gap: 8px;

    .search-bar-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid styles.$lavenderPinocchio;
      background: styles.$whiteColor;

      img {
        margin-left: 10px;
        filter: opacity(0.5);
      }

      input {
        width: 100%;
        border: none !important;
        color: styles.$blackColor !important;
        font-weight: 400 !important;
        letter-spacing: 0.5px !important;
      }

      &:hover,
      &:focus {
        outline: none;
        border: 1px solid styles.$pacificBlueColor !important;
        border-left: unset;
        box-shadow: none;
      }
    }
  }

  .options-container {
    display: flex;
    flex-direction: column;
    padding: 8px 8px;
    width: 100%;
    gap: 4px;
    max-height: 150px;
    overflow-y: auto;

    .option-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 8px;
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        background-color: styles.$athensGray;
      }
    }

    .option-selected {
      background-color: styles.$athensGray;
    }

    .no-result {
      font-size: 14px;
      margin-left: 8px;
      color: styles.$torchRed;
    }
  }
}
