@use 'Variable/_variable.scss' as colors;

.skeleton-card {
  &__table {
    margin-top: 20px;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    background-color: colors.$whiteSmoke;
    border-radius: 6px;
    border: 1px solid colors.$lightGrayColor;
  }

  &__column {
    width: 90%;
  }

  &__image {
    width: 100% !important;
    height: 100% !important;
  }

  &__reviewers {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
