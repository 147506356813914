@use 'Variable/_variable.scss' as colors;

.modal {
  border-radius: 16px;
  max-width: 1350px;
  overflow: hidden;
  padding-bottom: 20px;

  :global(.ant-modal-body) {
    padding: 0;
    height: 100%;
  }
  :global(.ant-modal-header) {
    border-bottom: none;
    padding: 0;
  }
  :global(.ant-modal-content) {
    padding: 0;
    min-width: 712px;
  }
}

.image-search-services-list {
  .image-search-service-button:not(:last-child) {
    margin-right: 42px !important;
  }
}

.image-search-service-button {
  height: auto;
  position: relative;

  &:after {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: colors.$blackColor;
    left: 0;
    bottom: 0;
  }

  &:hover:not([disabled]):after,
  &.image-search-service-button-active:after {
    content: '';
  }
}

.search-input {
  background: colors.$whiteColor;
  border: 1px solid colors.$lavenderPinocchio !important;
  border-radius: 8px;

  &:active,
  &:focus {
    box-shadow: none;
  }
}

.image-list {
  max-height: 305px;
  overflow: auto;

  .image-wrapper {
    width: 200px;
    height: 125px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .image {
      $border-width: 4px;

      outline: 0 solid colors.$darkCyanColor;
      transition: all 0.1s;
      max-width: 100%;
      max-height: 100%;

      &.selected-image {
        outline-width: $border-width;
        outline-offset: -$border-width;
      }
    }
  }
}

.loader {
  width: 100%;
}

.upload-file-wrapper {
  width: auto !important;
  background: colors.$milkWhite !important;
  border: 2px dashed rgba(colors.$easternBlue, 0.2) !important;
  border-radius: 12px !important;
  padding: 36px;

  :global(.ant-upload-btn) {
    padding: 0 !important;
  }

  .upload-cloud-icon-wrapper {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(colors.$easternBlue, 0.1);
    outline: 4px solid rgba(colors.$easternBlue, 0.03);
    border-radius: 100%;

    svg {
      position: relative;
      top: -1px;
      left: 1px;
    }
  }
}

.black-bin-icon path {
  fill: colors.$blackColor;
}

.local-image {
  $border-radius: 8px;

  width: 168px;
  height: 168px;
  position: relative;

  &.loading-wrapper img {
    filter: blur(2px) grayscale(100%);
  }

  &.big-image img {
    border: 2px solid colors.$rossoCorsa;
  }

  .big-image-alert {
    background-color: colors.$rossoCorsa;
    color: colors.$whiteColor;
    border-radius: $border-radius $border-radius 0 0;
    padding: 0 10px 0;
  }

  .infinity-loading,
  .progress-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .progress-loading {
    :global(.ant-progress-text) {
      color: colors.$easternBlue;
      text-shadow: colors.$whiteColor 0 0 2px;
    }
    :global(.ant-progress-circle-path) {
      stroke: colors.$easternBlue;
    }
  }

  .inner-local-image,
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: $border-radius;
  }
}

.divider {
  border-top-color: colors.$dawnPink;
}
