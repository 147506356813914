@use 'Variable' as styles;

.downloadSection {
  height: 190px;
  background-color: styles.$alabasterColor;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
  margin: 0px 24px 40px 12px;
  border-radius: 12px;
}

.downloadSection__header {
  @include styles.flexDisplay(flex, space-between, center);
  padding: 11px;
  span {
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.55px;
  }

  .downloadSection__downloadButton {
    @extend .secondaryNewDesignButton;
    text-transform: uppercase;
    font-size: 12px;
  }
  .downloadSection__downloadButton:hover {
    background-color: styles.$primaryTransparentButtonHoverColor;
  }
}

.downloadSection__imagesContainer {
  @include styles.flexDisplay(flex, flex-start, center);
  button {
    border: none;
    margin: 12px 0px 12px 12px;
    cursor: pointer;
    border: 1px solid styles.$replyTextColor;
    img {
      max-width: 144px;
      max-height: 100px;
      object-fit: contain;
    }
  }
}
