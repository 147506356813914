@use 'src/Variable/_variable.scss' as colors;

.modalClass {
  width: 400px !important;
  flex-direction: column;
  align-items: flex-start;

   :global(.ant-modal-content) {
    border-radius: 12px;
  }
  :global(.ant-modal-body) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 32px;
  }


  .button {
    width: 50%;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid  rgba(0, 0, 0, 0.08);
    /* Elevation/Button Default or Notification badges */
    box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.04);
  }
  .cancel {
    background: colors.$whiteColor;
    color: colors.$blackColor;
    border: 1px solid colors.$coolGrey;
  }
  .delete {
    background: colors.$rossoCorsa;
  }
}