@use 'src/Variable' as styles;

.sort-button-container {
  display: flex;
  flex-direction: column;

  .sort-button {
    width: 182px;
    gap: 2px;
    height: 36px;
    padding: 8px 8px 8px 12px;
    border-radius: 8px;
    border: 1px solid styles.$lavenderPinocchio;
    background: styles.$whiteColor;

    &:hover, &:focus {
      color: styles.$carbonGrey !important;
      border-color: styles.$lavenderPinocchio !important;
    }

    .sort-button-context {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      color: styles.$carbonGrey;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.25px;
    }

    .sort-option {
      color: styles.$blackColor;
    }
  }
}

.sort-button-container-popover {
  :global(.ant-popover-inner) {
    border-radius: 8px;
    border: 1px solid styles.$lavenderPinocchio;
  }

  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}

.sort-options{
  width: 197px !important;
  padding: 4px 0px;
  border-radius: 8px;
  background: styles.$whiteColor;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  .sort-options-title {
    display: flex;
    padding: 8px 14px;
    justify-content: space-between;
    align-items: center;
    color: styles.$gray;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .sort-option-container {
    padding: 2px 6px;
    display: block;
    width: 100%;
    border: none;
    background: transparent;
  }

  .options {
    display: flex;
    height: 32px;
    padding: 6px 10px 6px 8px;
    border-radius: 6px;
    gap: 8px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: styles.$blackHaze;
    }

    .text {
      margin-left: 0;
      color: styles.$blackColor;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-transform: capitalize;
    }
  }

  .option-selected {
    background: styles.$blackHaze;
  }
}

.arrow-drop-down-icon {
  transition: all 0.3s;

  &.active-arrow-drop-down-icon {
    transform: rotateX(180deg);

    path {
      fill: styles.$easternBlue;
    }
  }
}
