.button-container {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #5274db !important;
  border-radius: 4px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 16px;
  display: flex;

  .logo-container {
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    border-radius: 24.75px;
  }

  .button-text {
    color: white;
  }
}

@media screen and (max-width: 991px) {
  .button-container {
    width: 100%;
  }
}
