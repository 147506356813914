@use 'Variable' as styles;
@use 'styles/screen_size.scss' as screenSize;
@use 'styles/z_index.scss' as zIndexSize;

.missingProjects {
  @include styles.flexDisplay(flex, center, center);
  flex-direction: column;
  width: 100%;
  .missingProjects__icon {
    img {
      width: 322px;
      height: 364px;
    }
  }
}

.missingProjects__textContainer {
  @include styles.flexDisplay(flex, center, center);
  flex-direction: column;
  margin: 0px 24px 12px;
  width: 27rem;
  font-family: 'Montserrat';
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.18px;

  .missingProjects__firstHeading {
    margin-bottom: 12px;
    line-height: 24px;
  }
}

@media only screen and (max-width: screenSize.$screen-sm) {
  .missingProjects {
    .missingProjects__icon {
      padding: 18px 60px;
      img {
        height: 220px;
        width: 220px;
      }
    }
  }
  .missingProjects__textContainer {
    margin: 4px 12px;
    width: 20rem;
    font-size: 13px;
    .missingProjects__firstHeading {
      margin-bottom: 4px;
    }
  }
}

.addProject__buttonContainer {
  @include styles.flexDisplay(flex, space-between, center);
  .addProject__addButton {
    @include styles.flexDisplay(flex, space-between, center);
    margin-right: -20px;
    background-color: styles.$tradeWind;
    @extend .primaryNewDesignButton;
    span {
      @include styles.flexDisplay(flex, center, center);
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 1.25px;
      text-transform: uppercase;
    }
  }
  .addProject__addButton:hover {
    background-color: styles.$primaryWorkspaceButtonHoverColor;
  }
}

@media only screen and (max-width: screenSize.$screen-xs) {
  .missingProjects {
    .missingProjects__icon {
      padding: 24px 20px;
      img {
        height: 160px;
        width: 160px;
      }
    }
  }
  .missingProjects__textContainer {
    margin: 0px 0px;
    width: 16rem;
    font-size: 10px;
    .missingProjects__firstHeading {
      margin-bottom: 4px;
    }
  }
}
