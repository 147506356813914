@use 'src/Variable/index' as styles;

.home-wrapper {
  background: styles.$alabasterColor;
  height: 100vh;
  overflow: auto;
  padding-bottom: 50px;
}

.container {
  max-width: 1156px;
  padding: 0 16px;
  margin: 0 auto;
}
