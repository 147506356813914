@use 'src/Variable' as styles;

$titleWidth: 280px;
$selectContainerWidth: 512px;

.section {
  border-top: 1px solid styles.$dawnPink;
}

.section__container{
  display: flex;
  margin-top: 24px;
  .title {
    width: $titleWidth;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .select__container {
    width: $selectContainerWidth;
    border-radius: 5px;
    border: 1.5px solid styles.$dawnPink;
  }
}
