@use 'Variable/_variable.scss' as colors;

$bg-color: colors.$porcelain;
$active-color: colors.$easternBlue;

.logo-invision-list {
  :not(:last-child) {
    margin-right: 16px;
  }
}

.logo-invision {
  width: 212px;
  height: 98px;
  background: colors.$porcelain;
  border-radius: 7px;
  border: 2px solid $bg-color;
  position: relative;
  transition: all 0.5s;
  cursor: pointer;

  &[disabled] {
    cursor: auto;
  }

  &.logo-invision-active {
    & {
      border-color: $active-color;
    }
    &,
    &:after {
      border-color: $active-color;
    }
    &:before {
      background-color: $active-color;
    }
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    transition: all 0.5s;
    border-radius: 100%;
  }

  &:after {
    top: 12px;
    left: 12px;
    width: 14px;
    height: 14px;
    border: 1px solid colors.$disableGrayColor;
  }
  &:before {
    top: 15px;
    left: 15px;
    width: 8px;
    height: 8px;
    background: $bg-color;
  }
}
