@use 'src/Variable' as styles;

.kanban-board-container {
  padding: 24px;
  padding-bottom: 12px;
  background: styles.$alabaster;
  height: calc(100vh - 64px);
  overflow: auto;
}

.columns-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: auto;
}

.kanban-board {
  display: flex;
  gap: 16px;

  & > div {
    max-width: 320px;
    min-width: 320px;
  }
}

.notification {
  display: flex;
  width: 300px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid rgba(styles.$blackColor, 0.08);
  background-color: styles.$whiteColor;
  :global(.ant-notification-notice-message) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: styles.$blackColor;
  }
  :global(.ant-notification-notice-description) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: styles.$silverChalice;
  }
}

.kanban-board-with-blur {
  filter: blur(4px);
}

.droppable-col {
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  border: 1px dashed;
}

.large-spinner {
  :global(.ant-spin) {
    height: 100%;
  }

  :global(.ant-spin-dot) {
    font-size: 78px;
  }

  :global(.ant-spin-dot-item) {
    width: 24px !important;
    height: 24px !important;
    transform: none !important;
    animation: none !important;
  }
}
