@use 'src/Variable/index' as styles;
@use 'src/styles/screen_size.scss' as screenSize;
@use 'src/styles/rem.scss' as rescale;

.project-list-wrapper {
  border-top: 2px solid styles.$dawnPink;
}

.dashboard {
  @include styles.flexDisplay(flex, left, left);
}

.dashboard__body {
  @include styles.flexDisplay(flex, inherit, inherit);
  flex-direction: column;
  flex: 1;
  margin-left: rescale.px-to-rem(60px);
}

.project-list-content {
  @include styles.flexDisplay(flex, space-between, inherit);
  flex-direction: column;
}

.project-heading {
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.25px;

  color: styles.$projectThumbnailColor;
}

.create-project-btn {
  width: 155px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
}

.dashboard__upperNav {
  margin-top: rescale.px-to-rem(8px);
  height: rescale.px-to-rem(40px);
  text-align: center;
}

.dashboard__navHeading {
  @include styles.textStyle(rescale.px-to-rem(28px), 600, styles.$blueStoneColor);
  @include styles.flexDisplay(flex, flex-start, center);
  text-align: center;
  letter-spacing: 0.25px;
  line-height: 35px;
}

.dashboard__navRight {
  @include styles.flexDisplay(flex, flex-end, center);
  flex: 0.1;
  button {
    @extend .buttonStyle;
    border-radius: 30px;
    background-color: styles.$dashboardSecondaryColor;
    border: none;
  }
}

.dashboard__allBalanceContainer {
  @include styles.flexDisplay(flex, flex-start, center);
}

.dashboard__withdrawContainer {
  @include styles.flexDisplay(flex, center, center);
  margin-right: 12%;
  width: rescale.px-to-rem(520px);

  .dashboard__balanceContainer {
    margin-right: rescale.px-to-rem(16px);
    .dashboard__balanceText {
      color: styles.$ironsideGreyColor;
      font-size: rescale.px-to-rem(12px);;
    }
    .dashboard__balanceValue {
      margin-left: rescale.px-to-rem(4px);
      color: styles.$greyColor;
      font-size: rescale.px-to-rem(16px);
    }
  }

  .dashboard__buttonContainer {
    button {
      @extend .commentSendButton;
      padding: rescale.px-to-rem(4px) rescale.px-to-rem(12px);
      font-size: rescale.px-to-rem(10px);
      font-weight: 500;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: screenSize.$screen-sm) {
  .dashboard__navHeading {
    font-size: rescale.px-to-rem(20px);
  }
}

@media only screen and (max-width: screenSize.$screen-xs) {
  .dashboard__navHeading {
    font-size: rescale.px-to-rem(16px);
  }

  .dashboard__navRight {
    margin-right: rescale.px-to-rem(-24px);
    button {
      padding: rescale.px-to-rem(8px) rescale.px-to-rem(40px);
      border-radius: 30px;
      background-color: styles.$dashboardSecondaryColor;
      border: none;
    }
  }
}
