@use 'Variable/index' as styles;

$thumbnailBorderRadius: 10px;

.thumbnail {
  margin: 20px 24px 0 0;
  position: relative;
  width: 200px;
  height: 300px;
}

.thumbnail__content {
  @include styles.flexDisplay(flex, space-between, inherit);
  flex-direction: column;
  cursor: pointer;
  border: 1px solid styles.$feedBackgroundColor;
  border-radius: $thumbnailBorderRadius;
}

.is__loading {
  .loader__image,
  .content__1 {
    background: #eee linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: shine 1.5s linear infinite;
  }

  .loader__image {
    height: 175px;
    border-radius: 5px;
  }

  .content__1 {
    margin: 0.5em 0.2em;
    height: 25px;
    border-radius: 5px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
