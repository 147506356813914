@use 'Variable/_variable.scss' as colors;

$file-icon-width: 130px;
$file-icon-height: 130px;

.file-icon-wrapper {
  position: relative;
  width: $file-icon-width;
}

.image,
.file-icon {
  width: $file-icon-width;
  height: $file-icon-height;
  object-fit: contain;
}

.file-icon {
  background: colors.$greyGoose;
  text-transform: uppercase;
  border-radius: 8px;

  :global(.ant-image-placeholder) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.remove-file-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: colors.$greyGoose !important;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  width: 20px;
  height: 20px;
  border-radius: 100%;

  &:hover {
    background-color: colors.$greyColor !important;

    path {
      fill: colors.$whiteColor;
    }
  }
}
