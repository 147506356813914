@use '../../Variable' as styles;

.imageModal {
  padding: 10px 40px 72px 40px;
  position: relative;
  img {
    max-height: 370px;
    max-width: calc(100% - 84px);
    object-fit: contain;
    border: 1px solid styles.$feedBackgroundColor;
  }
  .download-button {
    @extend .primaryWorkspaceButtonDesign;
    padding: 7px 16px;
    font-size: 11px;
    font-weight: 400;
    position: absolute;
    right: 16px;
    bottom: 0px;
    margin-bottom: 10px;
  }
  button:hover {
    background-color: styles.$primaryWorkspaceButtonHoverColor;
  }

  .imagesContainer {
    max-width: 100%;
  }
}

.nav-button {
  border: none;
  background-color: styles.$primaryWorkspaceButtonColor;
  border-radius: 4px;
  margin: 0px 4px;
  min-width: 32px;
  min-height: 32px;
  cursor: pointer;
}
