@use 'src/styles/screen_size.scss' as screenSize;
@use 'src/Variable/index' as styles;
@use 'src/styles/rem.scss' as rescale;

.navigation__button {
  padding: rescale.px-to-rem(14px) rescale.px-to-rem(12px) rescale.px-to-rem(14px);
  font-size: rescale.px-to-rem(14px);
  font-weight: 500;
  letter-spacing: 1.25px;
  color: styles.$notesTextColor;
  margin-right: rescale.px-to-rem(12px);
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-align: center;
}

.tab__active,
.tab__inactive {
  border-bottom: 3px solid;
}
.tab__active {
  color: styles.$easternBlue !important;
  border-bottom-color: styles.$easternBlue !important;
}
.tab__inactive {
  color: styles.$notesTextColor;
  border-bottom-color: styles.$concreteColor;
}

@media only screen and (max-width: screenSize.$screen-sm) {
  .navigation__button {
    padding: rescale.px-to-rem(0px) rescale.px-to-rem(12px) rescale.px-to-rem(4px);
    font-size: rescale.px-to-rem(10px);
    margin-right: rescale.px-to-rem(8px);
    margin-top: rescale.px-to-rem(36px);
  }
}

@media only screen and (max-width: screenSize.$screen-xs) {
  .navigation__button {
    padding: rescale.px-to-rem(0px) rescale.px-to-rem(4px) rescale.px-to-rem(4px);
    font-size: rescale.px-to-rem(8px);
    margin-right: rescale.px-to-rem(8px);
    margin-top: rescale.px-to-rem(40px);
  }
}
