@use 'Variable/_variable.scss' as colors;

.payment__status_requested {
  color: colors.$blueIvy;
}

.payment__status_initiated {
  color: colors.$blueIvy;
}

.payment__status_captured {
  color: colors.$forestGreen;
}

.payment__status_denied {
  color: colors.$fuzzyWuzzyBrown;
}
