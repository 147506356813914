@use 'src/Components/ReactQuill/styles.module.scss';

.notification-wrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;

  .notification-title {
    float: left;
    margin-right: 5px;
  }

  p {
    margin: 0;
  }

  :global(.mention) {
    @extend .user-mention;
  }
}
