@use 'src/Variable/_variable.scss' as colors;
@use 'src/styles/z_index.scss' as zIndexSize;

:export {
  defaultTextareaBackgroundColor: colors.$whiteColor;
}

.comment-form {
  position: relative;

  &.comment-form-with-tools {
    $border-radius: 8px;

    border: 1px solid colors.$lavenderPinocchio;
    border-radius: $border-radius;

    .tools-box {
      background: colors.$linkWaterColor;
      border-top: 1px solid colors.$lavenderPinocchio;
      border-radius: 0 0 $border-radius $border-radius;
    }
  }
}

.textarea {
  :global(.ql-toolbar),
  :global(.ql-container),
  :global(.ql-editor) {
    border: none;
    padding: 0;
    max-width: 100%;
  }

  :global(.ql-toolbar) {
    display: none;
  }

  :global(.ql-container) {
    padding: 16px;
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: colors.$carbonGrey;
  }

  :global(.ql-editor) {
    max-height: 400px;
    overflow: auto;

    &:global(.ql-blank):before {
      font-style: normal;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: rgba(colors.$blackColor, 0.3);
    }
  }

  &.textarea-with-border {
    :global(.ql-container) {
      border: 1px solid colors.$easternBlue !important;
      border-radius: 8px;
    }
  }

  &.textarea-with-icon :global(.ql-editor) {
    &:before {
      left: 35px;
    }
    > *:first-child {
      text-indent: 22px;
    }
  }
}

.tools-box {
  .pin-wrapper {
    border-right: 1px solid colors.$concreteColor;
  }

  .mod-button {
    width: 40px;
    height: 40px;
    &:hover,
    &:focus {
      background: colors.$tradeWind;
    }

    &:not(.mod-button-is-active) {
      svg path {
        stroke: colors.$tradeWind;
        fill: colors.$tradeWind;
      }
      &:hover,
      &:focus {
        background: transparent;
      }
    }
  }
}

.input-icon {
  position: absolute;
  z-index: zIndexSize.$first-layer;
  top: 17px;
  left: 16px;
}
