@use 'src/Variable' as styles;

.task-card {
  width: 50%;
  border: 1px solid styles.$silver;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  &:hover {
    background: styles.$twilightBlue;
  }
}

.task-card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: .25px;
  margin-bottom: 8px;
}

.task-card-description {
  font-size: 16px;
  margin-bottom: 8px;
}

.task-card-label {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 16px;
  text-transform: uppercase;
  background: lightskyblue;
  color: styles.$whiteColor;
}

.task-card-priority {
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 16px;
  text-transform: uppercase;
  max-width: 70px;
  text-align: center;
}

.task-card-priority-high {
  background-color: styles.$persianRed;
  color: styles.$whiteColor;
}

.task-card-priority-medium {
  background-color: styles.$sunShade;
  color: styles.$whiteColor;
}

.task-card-priority-low {
  background-color: styles.$sushi;
  color: styles.$whiteColor;
}

.task-card-priority-none {
  background-color: styles.$alto;
  color: styles.$boulder;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  height: 32px;
  &:hover {
    background: styles.$tradeWind;
    color: styles.$whiteColor;
  }
}
