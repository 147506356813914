@use 'src/Variable/_variable.scss' as styles;
$margin-left: 20px;

.expert__subtitle {
  font-size: 14px;
  font-weight: 400;
  color: styles.$carbonGrey;
  margin-top: 4px;
}

.expert__cardWrapper {
  width: calc(100% - 2 * $margin-left);
  padding: 6px 10px 6px 10px;
  margin-left: $margin-left;
  margin-right: $margin-left;
  text-align: left;
  background: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: styles.$ziggurat;
  }
}

.expert__avatar {
  margin-top: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
