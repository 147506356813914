@use 'Variable/_variable.scss' as colors;

$step-wrapper-border-radius: 12px;

.step-wrapper {
  background: colors.$linkWaterColor;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: $step-wrapper-border-radius;
}

.header {
  background: colors.$whiteColor;
  border-radius: $step-wrapper-border-radius $step-wrapper-border-radius 8px 8px;
}

.share-button {
  color: colors.$pacificBlueColor;
  border-color: colors.$pacificBlueColor;
  background-color: transparent;

  svg path {
    fill: colors.$pacificBlueColor;
    fill-opacity: 1;
  }

  &:hover {
    color: colors.$whiteColor;
    background-color: colors.$pacificBlueColor;
    border-color: colors.$pacificBlueColor;

    svg path {
      fill: colors.$whiteColor;
    }
  }
}
