@use 'Variable/_variable.scss' as colors;
@use 'styles/rem.scss' as *;
@use 'src/Pages/ProjectDetails/components/ProjectDetailsHeader/styles.module.scss' as projectDetailsHeader;

.milestone-wrapper {
  height: calc(100vh - px-to-rem(projectDetailsHeader.$header-navigation-height));
  overflow: hidden;
}

.header-wrapper {
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

.milestone-title{
  font-size: 24px;
  max-width: 600px;
  line-height: 30px;
  word-wrap: break-word;
}

.share-button {
  color: colors.$easternBlue;

  svg path {
    fill: colors.$easternBlue;
    fill-opacity: 1;
  }

  &:hover {
    color: colors.$whiteColor;
    background-color: colors.$easternBlue !important;

    svg path {
      fill: colors.$whiteColor;
    }
  }
}

.workspace-content-wrapper {
  display: flex;
  height: calc(100% - 70px);
}

.workspace-content {
  flex: 1 1 85%;
  overflow: auto;
  transition: all 0.3s;
  height: 100%;

  .show-embedded-panel & {
    flex-basis: 60%;
  }
}

.workspace-toolbar-wrapper {
  transition: all 0.3s;
  height: 100%;

  .show-embedded-panel & {
    flex-basis: 350px;
  }
}
