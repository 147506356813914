@use 'Variable/_variable.scss' as colors;

$main-bg-color: colors.$easternBlue;

.invert-button,
.button {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  border-radius: 8px;
  border: 1px solid $main-bg-color;

  &:global(.ant-btn-primary) {
    text-shadow: none;
  }
}

.button {
  color: colors.$whiteColor;
  background-color: $main-bg-color;
  border: 1px solid rgba(colors.$blackColor, 0.08);
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
}

.invert-button {
  color: $main-bg-color;
  background-color: transparent;
}

.smokey-button {
  color: rgba(colors.$blackColor, 0.87);
  background-color: colors.$smokeyColor;
  border: 1px solid rgba(colors.$blackColor, 0.24);
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
}

.rossoCorsa-button {
  color: colors.$whiteColor;
  background-color: colors.$rossoCorsa;
  border: 1px solid rgba(colors.$blackColor, 0.08);
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);

  &:hover {
    border-color: colors.$rossoCorsa;
    color: colors.$rossoCorsa;
  }

  &.invert-button {
    color: colors.$rossoCorsa;
    background-color: colors.$whiteColor;
    border: 1px solid colors.$rossoCorsa;

    &:hover {
      color: colors.$whiteColor;
      background-color: colors.$rossoCorsa;
    }
  }
}

.fuzzyWuzzyBrown-button {
  color: colors.$whiteColor;
  background-color: colors.$fuzzyWuzzyBrown;
  border: 1px solid rgba(colors.$blackColor, 0.08);
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);

  &:hover {
    border-color: colors.$fuzzyWuzzyBrown;
    color: colors.$fuzzyWuzzyBrown;
  }

  &.invert-button {
    color: colors.$fuzzyWuzzyBrown;
    background-color: colors.$whiteColor;
    border: 1px solid colors.$fuzzyWuzzyBrown;

    &:hover {
      color: colors.$whiteColor;
      background-color: colors.$fuzzyWuzzyBrown;
    }
  }
}

.white-button {
  color: colors.$blackColor;
  background-color: colors.$whiteColor;
  border: 1px solid colors.$coolGrey;
  box-shadow: none;
}
