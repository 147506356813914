@use "src/Variable" as styles;

.listing-header-wrapper {
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  background: styles.$whiteColor;
}

.listing-header-container {
  max-width: 1156px;
  padding: 0 16px;
  margin: 0 auto;
  height: 65px;
}

.logoImage {
  width: 140px;
  height: 25px;
  object-fit: contain;
}

.nav-items-wrapper {
  gap: 68px;
}

.nav-item {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.15px;
  color: styles.$projectThumbnailColor;
  opacity: 0.8;
  &:hover {
    color: styles.$easternBlue;
  }
}