@use "sass:math";

$html-font-size: 16px;

@function remove-px($px) {
  @if math.unit($px) == 'px' {
    @return math.div($px, 1px);
  }

  @return $px;
}

@function px-to-rem($px) {
  @return math.div(remove-px($px), math.div($html-font-size, 1px)) + rem;
}
