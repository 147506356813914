@use '../Variable' as styles;
@use './rem.scss' as *;

@forward './text.scss';
@forward './spacing.scss';
@forward './flex.scss';

html {
  font-size: $html-font-size;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  outline: none;
}

.show {
  display: block;
}

.hide {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.select-none {
  user-select: none;
}

.h-fit {
  height: fit-content;
}

.width-full {
  width: 100%;
}

.pointer {
  cursor: pointer
}

.overflow-hidden {
  overflow: hidden
}

.text-button {
  &,
  &:hover,
  &:active,
  &:focus,
  &:before {
    border: none;
    background-color: transparent;
    padding: initial;
    margin: initial;
  }
}
