@use 'src/Variable' as styles;

.collaborator-invite {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 8px 0px;

  :global(.ant-form-item) {
    margin-bottom: 0;
    max-width: 233px;
  }

  :global(.ant-select-selector) {
    margin-bottom: 0 !important;
  }

  .input-item {
    display: flex;

    :global(.ant-form-item-explain-error) {
      font-size: 11px;
      color: styles.$rossoCorsa;
      margin-left: 4px;
      position: absolute;
    }
  }

  .invite-button {
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    background: styles.$easternBlue;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    letter-spacing: 0.12px;
  }

  .disabled {
    background: styles.$silverDark;
    pointer-events: none;
  }
}

.error-message {
  color: styles.$rossoCorsa;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 16px;
}

.reduced-margin {
  margin: 4px 0px;
}
