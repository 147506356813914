@use 'src/Variable' as styles;

.progressModal__body {
  margin: 8px 28px 56px 28px;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: styles.$notificationDateColor;
}

.progressModal__buttonsContainer {
  @include styles.flexDisplay(flex, flex-end, center);
  margin-right: 20px;

  .progressModal__firstBtn,
  .progressModal__secondBtn {
    margin-left: 28px;
    margin-bottom: 20px;
  }

  .progressModal__firstBtn {
    @extend .transparentWorkspaceButtonDesign;
  }

  .progressModal__secondBtn {
    @extend .primaryWorkspaceButtonDesign;
    padding: 8px 20px;
    border-radius: 16px;
    font-size: 13px;
  }
}
