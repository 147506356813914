@use 'src/Variable' as styles;

.newMilestone__modalHeading {
  padding: 22px 32px;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: 0.25px;
  color: styles.$projectThumbnailColor;
  margin-bottom: 0px;
  border-bottom: 1px solid;
  border-color: styles.$primaryButtonBorderColor;
}

.newMilestone__modal {
  border-radius: 10px;

  :global(.ant-select-selector) {
    box-shadow: none !important;
  }
  :global(.ant-modal-content) {
    border-radius: 12px;
    padding: 0px 0px;
    min-width: 712px;
  }
  :global(.ant-modal-body) {
    padding: 0;
    border-radius: 0 0 8px 8px;
    height: 100%;
  }
  :global(.ant-modal-close-icon) {
    margin: 32px 0px;
    margin-right: 37px;
  }
}

.addMilestone__transparentButton {
  @extend .transparentWorkspaceButtonDesign;
  font-size: 14px;
  padding: 12px 24px;
  margin-right: 32px !important;
  color: styles.$lochinvar;
}

.addMilestone__primaryModalButton {
  @extend .primaryWorkspaceButtonDesign;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px 24px;
  height: 40px;
}

.addMilestone__primaryModalButton:hover {
  background-color: styles.$primaryWorkspaceButtonHoverColor;
  color: styles.$whiteColor !important;
}

.milestoneName {
  max-width: 50%;
  padding: 36px 32px;
  .milestoneName__title{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: styles.$projectThumbnailColor;
  }
}

.modalFooter {
  background: styles.$alabaster;
  border-top: 1px solid styles.$primaryButtonBorderColor;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  .modalFooter__buttons {
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
