@use 'Variable/_variable.scss' as colors;
@use 'styles/z_index.scss' as zIndexSize;

.date-picker-wrapper {
  display: inline;
  position: relative;

  &:global(.ant-form-item) {
    vertical-align: initial;
  }
  :global(.ant-picker-input) {
    display: inline-flex;
    width: auto;
  }

  :global(.ant-picker) {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;

    &,
    input {
      padding: 0;
      color: inherit;
      font-size: inherit !important;
      font-weight: inherit;
      transition: none;
    }
  }
}

.date-picker-size-checker {
  position: absolute;
  visibility: hidden;
  z-index: -1 * zIndexSize.$third-layer;
  display: block;
  white-space: nowrap;
}
