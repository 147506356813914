@use '../../Variable' as styles;

.login__navigation {
  @include styles.flexDisplay(flex, space-between, center);
  padding: 0;
  height: 76px;
  max-width: 1130px;
  margin: 0 auto;

  .login__leftNav {
    margin-left: -4px;
    button {
      @extend .logoStyle;
      border: none;
    }
    img {
      height: 31px;
      object-fit: contain;
    }
  }
}

.PrivacyPolicy {
  padding: 2em;
  max-width: 1024px;
  margin: 0 auto;
}
ul {
  margin: 2em;
}
h1, h2, h3 {
  font-weight: bold;
}

.Header {
  padding: 0 4em;
}

.footer__links {
  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
