@use 'src/Variable' as styles;
@use 'src/Pages/ProjectDetails/sub-routes/Board/components/TaskDetailsPopup/styles.module.scss' as taskDetailsPopup;

@keyframes changeColor {
  0% {
    border: double 2px transparent;
    background-image: linear-gradient(styles.$whiteColor, styles.$whiteColor), radial-gradient(circle at top left, styles.$oldRose, styles.$kleinBlue);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  100% {
    border: double 2px transparent;
    background-image: linear-gradient(styles.$whiteColor, styles.$whiteColor), radial-gradient(circle at top left, styles.$oldRoseLight, styles.$kleinBlueLight);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
}


.draggable-item {
  user-select: none;
  box-sizing: border-box;
  position: relative;

  display: grid;
  padding: 12px;
  margin-top: 12px;

  background: styles.$whiteColor;
  border: 1px solid styles.$mercury;
  box-shadow: 0px 2px 4px styles.$shadowBlack;
  border-radius: 12px;

  &:hover {
    .task-buttons {
      visibility: visible;
    }
  }

  :global(.ant-form-item) {
    margin-bottom: 0px;
  }

  :global(.ant-input) {
    border-radius: 4px;
    padding: 8px 12px;
    margin-bottom: 16px;
    border: 1px solid styles.$mercuryLight;
    color: styles.$blackColor;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
  }

  :global(.ant-input:focus) {
    border: 1px solid styles.$dodgerBlue;
  }

  :global(.ant-form-item-explain-error) {
    display: none;
  }
}

.add-border {
  animation: changeColor 3s;
  animation-fill-mode: forwards; // This makes sure that the animation stays at the last frame (ends at the last frame)
}


.task-buttons {
  visibility: hidden;
  position: absolute;
  display: flex;
  justify-self: flex-end;
  height: 28px;
  margin-top: 8px;
  margin-right: 8px;
  border: none;

  filter: drop-shadow(0px 0px 1px rgba(40, 41, 61, 0.04)) drop-shadow(0px 2px 4px rgba(96, 97, 112, 0.16));

  .edit-button,
  .option-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    background: styles.$whiteColor;
    border: 1px solid styles.$silverChalice;
  }

  .edit-button {
    border-radius: 8px 0px 0px 8px;
  }

  .option-button {
    border-radius: 0 8px 8px 0;
    border-left: none;
    img {
      filter: opacity(0.5);
    }
  }
}

.ant-dropdown-menu-item {
  padding: 0;
}

.task-card-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background: styles.$whiteColor;
  border: 1px solid styles.$mercuryLight;

  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 4px;

  :global(.ant-dropdown-menu-item) {
    padding: 8px 16px 0px 10px;
  }

  .menu-button {
    @include styles.flexDisplay(flex, flex-start, center);
    width: 100%;
    background-color: transparent;
    gap: 12px;
    border: none;
    cursor: pointer;
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .delete-button {
    padding-bottom: 8px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: styles.$blackColor;
  word-break: break-word;
}

.file-name-container {
  display: flex;
  align-items: center;
  color: styles.$gray;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  img {
    margin-right: 2px;
    margin-top: 2px;
    width: 16px;
    height: 16px;
  }
}

.tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 6px;

  .assignees {
    display: flex;
    align-items: center;

    .user-icon {
      height: 20px;
      max-width: 20px;
      border-radius: 20px;
      border: 1px solid styles.$whiteColor;
      background-color: styles.$whiteColor;
      object-fit: cover;
    }

    .move-icon-right {
      margin-right: -6px;
    }
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 2px;
  color: styles.$nevada;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-top: 4px;

  img {
    height: fit-content;
  }
}

.magic-wand-icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-right: 6px;

  :hover {
    visibility: visible;
  }
}

@each $priority, $priority-props in taskDetailsPopup.$priority-colors {
  .#{$priority}-priority {
    padding: 2px 6px;
    border-radius: 4px;
    background-color: map-get($priority-props, background-color) !important;
    text-transform: capitalize;
    color: styles.$shepraBlue;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    align-self: flex-end;

    &-circle {
      width: 7px;
      height: 7px;
      border-radius: 16px;
      margin-right: 8px;
      background: map-get($priority-props, circle-color);
    }
  }
}
