@use 'Variable/_variable.scss' as colors;

.comments-list-wrapper {
  height: 100%;
  overflow: auto;
}

.comments {
  overflow-y: auto;
  scroll-behavior: smooth;
  max-height: calc(100vh - 180px);
}

.comments-with-form {
  max-height: calc(100vh - 350px);
}

.no-comments {
  padding: 16px;
  background: #F5FEFF;
  border: 1px solid colors.$easternBlue;
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  color: colors.$blackColor;
  margin: 12px 0 0;
}

.thumbtack-icon path {
  stroke: colors.$easternBlue;
}

.reply-text-area {
  :global(.ql-container) {
    padding: 12px 8px;
    border-radius: 4px !important;
  }
}

.divider {
  :global(.ant-divider) {
    border-top: 1px solid colors.$dawnPink;
  }
}
