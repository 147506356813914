@use 'src/Variable' as styles;

.container {
  width: 100%;
  border-top: 2px solid styles.$dawnPink;
  border-bottom: 1px solid styles.$dawnPink;
}

.section__heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.25px;
  color: styles.$projectThumbnailColor;
}

.section__description {
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  opacity: 0.6;
}

.progress {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  opacity: 0.6;
}

.section__container {
  border-top: 1px solid styles.$dawnPink;

  .app__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 56px;
    height: 56px;

    background: white;
    border: 1px solid styles.$gallery;
    border-radius: 12px;
  }

  .title__container {
    width: 280px;
    margin-right: 32px;

    .app__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: black;
    }

    .app__description {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: styles.$dovegrey;
    }
  }
}
