@use 'src/Variable' as styles;

$priority-colors: (
  low: (
    background-color: styles.$selago,
    circle-color: styles.$electricViolet,
  ),
  medium: (
    background-color: styles.$justRight,
    circle-color: styles.$sunShade,
  ),
  high: (
    background-color: styles.$amour,
    circle-color: styles.$torchRed,
  )
);

$status-colors: (
  one: (
    background-color: styles.$vistaWhite,
  ),
  two: (
    background-color: styles.$selago,
  ),
  three: (
    background-color: styles.$blackSqueeze,
  ),
  four: (
    background-color: styles.$porcelainLight,
  )
);

@each $priority, $priority-props in $priority-colors {
  .#{$priority}-priority {
    :global(.ant-select-selector) {
      background-color: map-get($priority-props, background-color) !important;
    }

    &-circle {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 16px;
      margin-right: 8px;
      background: map-get($priority-props, circle-color);
    }
  }
}

@each $status, $status-props in $status-colors {
  .#{$status}-status {
    background: map-get($status-props, background-color) !important;
  }
}

.task-details-modal {
  border-radius: 8px;
  :global(.ant-modal-header) {
    border-bottom: 1px solid styles.$primaryButtonBorderColor;
    border-radius: 8px 8px 0px 0px;
    background-color: styles.$whiteColor;
    padding: 14px 16px;
  }
  :global(.ant-modal-content) {
    max-height: 80vh;
    display: flex;
    overflow: auto;
    border-radius: 4px;
    flex-direction: column;
  }

  :global(.ant-modal-title) {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    max-width: 95%;
    color: styles.$blackColor;
    letter-spacing: 0.25px;
  }

  :global(.ant-modal-body){
    flex-grow: 1;
    overflow: auto;
    padding: 0;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
  }
}

.custom-task-details-modal {
  :global(.ant-modal-header) {
    background-color: styles.$whiteColor;
    padding: 14px 16px;
  }
  :global(.ant-modal-body){
    padding: 0px;
  }
}

.task-description-container {
  cursor: pointer;
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: rgba(21, 147, 160, 0.06);

  :global(.ant-input) {
    border: none !important;
  }

  .task-description {
    margin-bottom: 0;
    color: rgba(styles.$blackColor, 0.87);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.25px;
  }
}

.details-modal-body {
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 10fr 8fr;
  padding: 0 24px;
}

.editable-title{
  background-color: transparent;
  width: 100%;
  border: 1.5px solid styles.$wedgeWood;
  padding: 6.5px;
  border-radius: 4px;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.25px;
}

.task-title-container {
  display: flex;
  align-items: center;
  gap: 6px;

  .task-title-icon {
    width: 24px;
    height: 24px;
    color: styles.$ceruleanBlue;
  }

  .task-title {
    color: styles.$ceruleanBlue;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.5px; /* 97.5% */
  }

  .custom-task-title {
    color: styles.$blackColor;
  }
}

.section-heading {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: styles.$blackColor;
  opacity: 0.6;
}

.figma-link {
  text-transform: none;
  color: styles.$blackColor;
  &:hover {
    text-decoration: underline;
    color: styles.$blackColor;
  }
}

.transcription-button {
  height: fit-content;
}

.link-text {
  color: styles.$blackColor;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.external-link-icon {
  width: 14px;
  height: 16px;
  margin-left: 8px;
  color: styles.$silverGray;
}

.tag-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.comment-container-wrapper {
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  padding-left: 16px;
  gap: 16px;
  border-left: 1px solid rgba(styles.$blackColor, 0.08);

  .sticky-note-container {
    background-color: styles.$mauve;
    padding: 24px;
    min-height: 270px;
    max-width: 365px;
    font-size: 16px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: styles.$dawnPink;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: styles.$silverChalice;
  }
}

.task-meta-wrapper {
  padding: 16px 0;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .indicator {
    display: flex;
    align-items: center;
    gap: 4px;

    color: rgba(styles.$blackColor, 0.60);
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.25px;
  }

  .tags {
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 4px;
    width: fit-content;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    border-radius: 16px ;
    gap: 2px;

    .close-icon {
      color: styles.$nevada;
      width: 9px;
      height: 11px;
      cursor: pointer;
    }
  }

  .add-button {
    height: fit-content;
    color: styles.$pacificBlueColor;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.5px;
  }

  .task-detail {
    display: flex;
    gap: 12px;

    .task-detail-title-container {
      display: flex;
      align-items: center;
      align-self: baseline;
      gap: 4px;
      min-width: 76px;

      .title {
        color: styles.$blackColor;
        font-variant-numeric: lining-nums tabular-nums;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }

    .assignee-button-container {
      display: flex;
      align-items: center;

      .ai-assignees {
        display: flex;
        height: 26px;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 24px;
        border: 1.5px dashed styles.$cornflowerBlue;
        margin-left: 6px;
        position: relative;
        cursor: pointer;

        .assignees {
          display: flex;
          gap: 2px;
        }

        .user-icon {
          height: 16px;
          border-radius: 8px;
          object-fit: cover;
        }

        &:hover .help-popup {
          visibility: visible !important;
        }

        .help-popup {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 24px;
          visibility: hidden;

          .arrow-top {
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-top: 0;
            border-bottom: 7px solid styles.$ceruleanBlue;
          }

          .help-text {
            color: styles.$whiteColor;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px; /* 123.077% */
            letter-spacing: 0.2px;
            background-color: styles.$ceruleanBlue;
            width: 138px;
            padding: 8px;
            border-radius: 8px;
            z-index: 10;
          }
        }
      }
    }
  }

  .assignee-detail {
    position: relative;
  }

  .save-button {
    margin-left: auto;
  }

  :global(.ant-select-arrow) {
    img {
      filter: grayscale(1);
    }
  }
}

.assignees-container {
  display: flex;
  flex-wrap: wrap;
}

.assignee {
  display: flex;
  align-items: center;
  padding: 2px;
  margin: 4px;
  padding-right: 8px;
  width: fit-content;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  gap: 6px;
  background-color: styles.$porcelainLight;
  border-radius: 20px;

  .user-icon {
    height: 26px;
    border-radius: 13px;
    object-fit: cover;
  }
}

.priority-select,
.status-select {
  display: flex;
  align-items: center;
  gap: 8px;
  height: fit-content !important;

  :global(.ant-select-selector) {
    margin-bottom: 0px;
    border-radius: 4px !important;
    border: none !important;
  }

  :global(.ant-select-selection-item) {
    color: styles.$blackColor;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding-right: 0px !important;
    width: fit-content !important;
  }

  :global(.ant-select-focused) {
    border: none !important;
    box-shadow: none !important;
  }
}

.comment-image {
  width: 100%;
  object-fit: contain;

  img {
    border-radius: 8px;
    border: 1px solid styles.$borderColor;
  }
}

.priority-explanation-text {
  color: styles.$nevada;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}

.transcription-title {
  margin-left: 8px;
  color: styles.$blackColor;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.icon {
  width: 16px;
  height: 16px;
}

.task-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .action-buttons-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .divider {
      width: 1px;
      height: 100%;
      background: rgba(styles.$blackColor, 0.08);
    }

    .footer-action-button {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      &:hover {
        color: styles.$pacificBlueColor;
      }
    }
  }
}
