@use 'Variable/_variable.scss' as colors;

.select-file-or-link-modal {
  :global(.ant-modal-body) {
    padding: 0 16px 32px;
  }
}

.upload-tabs {
  :global(.ant-tabs-nav):before {
    content: none;
  }

  :global(.ant-tabs-tab) {
    padding-top: 0;
    border-bottom: 2px solid colors.$concreteColor;

    :global(.ant-tabs-tab-btn) {
      color: rgba(0, 0, 0, 0.54);
    }

    &:global(.ant-tabs-tab-active) {
      :global(.ant-tabs-tab-btn) {
        color: colors.$easternBlue;
      }
    }
  }

  &[disabled] :global(.ant-tabs-tab) {
    pointer-events: none;
  }

  :global(.ant-tabs-ink-bar) {
    background: colors.$easternBlue;
  }
}

.upload-button {
  :global(.ant-upload-select-text) {
    display: block;
  }

  :global(.ant-upload-list-text-container) {
    margin-top: 10px;
  }

  :global(.ant-upload-list-item) {
    &:global(.ant-upload-list-item-uploading),
    &:global(.ant-upload-list-item-done) {
      :global(.ant-upload-list-item-card-actions) {
        display: none;
      }
    }

    :global(.ant-progress-bg) {
      background-color: colors.$easternBlue;
    }
  }
}

.link-input {
  &,
  &:focus,
  &:active {
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 4px;
    box-shadow: none;
    padding: 8px;
  }
}
