@use 'src/Variable/index' as styles;

.signin-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .auth-modal {
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: 416px;

    background-color: styles.$whiteColor;
    border-radius: 8px;
    padding: 48px;
    box-shadow: 0px 0px 16px 0px rgba(96, 97, 112, 0.12);

    .title-container {
      display: flex;
      align-items: center;
      padding: 4px 2px 4px 2px;
      gap: 10px;

      .text-icon {
        margin-top: 4px;
      }
    }

    .description {
      font-family: Outfit;
      font-size: 22px;
      font-weight: 400;
      line-height: 27.72px;
      text-align: left;
      color: rgba(styles.$blackColor, 0.8);
    }

     .back-button {
      border: none;
      background-color: styles.$whiteColor;
      text-align: left;
      letter-spacing: 0.01em;
      cursor: pointer;
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.8);
    }

    .partition {
      display: flex;
      align-items: center;
    }

    .line {
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
    }

    .half {
      width: 50%;
    }

    .or {
      margin: 0px 16px;
      font-family: 'Outfit';
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #B2B2B2;
    }

    .welcome-text {
      font-family: 'Outfit';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 32px;
    }

    .label {
      margin-bottom: 0px;
    }
    
    .email-input-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .input {
      margin-bottom: 8px;
      padding: 12px;
      background: styles.$whiteColor;
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 8px;

      &::placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: styles.$silverChalice;
      }
    }

    .forgot-password {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
      color: styles.$silverChalice;
      border: none;
      background-color: styles.$whiteColor;
      cursor: pointer;
    }

    .list {
      font-family: 'Outfit';
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #909090;
      margin: 0px;
      margin-left: 16px;
      margin-bottom: 8px;
    }

    .action-button {
      cursor: pointer;
      padding: 8px 12px;
      border: 1px solid styles.$pacificBlue;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.25px;
      color: styles.$pacificBlue;
      background-color: styles.$whiteColor;
      margin-top: 24px;
    }

    .disabled {
      background-color: styles.$concreteColor;
      border: 1px solid #d9d9d9;
    }

    .error-message {
      color: styles.$rossoCorsa;
      text-align: center;
      margin-top: 8px;
      line-height: 14px;
      font-size: 12px;
    }

    .terms {
      color: styles.$gray;
      text-align: center;
      margin-top: 40px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      a {
        text-decoration: underline;
        color: styles.$gray;
      }
    }
  }
}
