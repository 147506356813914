@use 'Variable/_variable.scss' as colors;

.checkbox {
  :global(.ant-checkbox-wrapper) {
    margin-right: 20px;

    & > span {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      padding-left: 15px;
      padding-right: 0;
    }
    span:global(.ant-checkbox) {
      padding: 0;
      margin: 0;
    }

    :global(.ant-checkbox-checked .ant-checkbox-inner) {
      background-color: colors.$pacificBlueColor;
      border-color: colors.$pacificBlueColor !important;

      &:after {
        border-color: colors.$alabasterColor !important;
      }
    }

    :global(.ant-checkbox-disabled) + span {
      color: inherit !important;
    }
  }
}
