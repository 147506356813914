@use 'src/Variable' as styles;

.import-file-container {
  display: flex;
  flex-direction: column;

  .import-file-text {
    color: rgba(styles.$blackColor, 0.6);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .figma-form-container {
    display: grid;
    grid-template-columns: 5fr 2fr;
    align-items: center;

    :global(.ant-form-item) {
      margin-bottom: 0px;
    }

    :global(.ant-input-affix-wrapper) {
      margin-top: 0px;
      border-radius: 8px;
      border-color: styles.$primaryButtonBorderColor;

      :global(.ant-input) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    :global(.ant-input-prefix) {
      padding: 0px 12px !important;
    }
  }

  .transcription-form-container {
    display: flex;
    flex-direction: column;

    textarea {
      min-height: 200px;
      resize: none;
    }

    :global(.ant-input) {
      margin-top: 8px;
      display: flex;
      padding: 6px 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      border: 2px solid rgba(styles.$blackColor, 0.08);
      background: styles.$whiteColor;
    }

    :global(.ant-input:focus) {
      border: 1px solid styles.$dodgerBlue;
    }

    :global(.ant-row) {
      flex-direction: column;

      :global(.ant-form-item-label) {
        display: flex;

        label {
          color: rgba(styles.$blackColor, 0.6);
          font-size: 16px;
          font-weight: 400;
          line-height: 160%; /* 25.6px */
        }

        label::before {
          display: none;
        }
        label::after {
          display: none;
        }
      }
    }
  }
}

.title-container {
  position: relative;
  display: flex;
  width: 100%;

  .back-navigation-text {
    cursor: pointer;
    color: rgba(styles.$blackColor, 0.6);
    font-size: 16px;
    font-weight: 400;
  }

  .modal-title {
    position: absolute;
    display: flex;
    justify-content: center;
    align-self: stretch;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.25px;
    margin-bottom: 0px;
  }
}

.add-figma-file-modal {
  background: styles.$linkWaterColor;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 8px;

  .add-figma-file-button {
    height: 40px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 1.25px;
    border-radius: 12px;
  }

  :global(.ant-input-prefix) {
    padding: 0 12px !important;
  }

  :global(.ant-modal-content),
  :global(.ant-modal-header) {
    background: transparent;
    padding: 0;
  }

  :global(.ant-modal-title) {
    display: flex;
    height: 60px;
    padding: 12px 20px 12px 32px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-bottom: 1px solid styles.$primaryButtonBorderColor;
  }

  :global(.ant-modal-body) {
    padding: 24px 24px 36px;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  :global(.ant-modal-close-x) {
    margin-top: 6px;
    padding-right: 20px;
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }

  :global(.ant-switch) {
    max-width: 32px;
    max-height: 20px;

    :global(.ant-switch-handle::before) {
      margin-bottom: 2px;
      margin-left: 2px;
    }
  }

  :global(.ant-switch-checked) {
    background: styles.$shamrock;
    border-radius: 40px;
  }

  :global(.ant-modal-footer) {
    padding: 16px 24px 16px 16px;
    background: styles.$alabaster;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.reduced-padding {
  :global(.ant-modal-body) {
    padding: 0px 0px 24px 0px !important; 
  }
}

.add-figma-file-button {
  height: 40px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: 1.25px;
  border-radius: 12px;
}
