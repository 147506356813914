@use 'Variable/_variable.scss' as colors;
@use './screen_size.scss' as screenSize;

.text {
  font-smoothing: antialiased;
}

// Usage className="text-{colorName}"
// Example: className="text-yellow"
$text-colors: (
  white: colors.$whiteColor,
  black: colors.$blackColor,
  yellow: colors.$yellowColor,
  blue: colors.$blueColor,
  grey: colors.$greyColor,
  blueStone: colors.$blueStoneColor,
  terracotta: colors.$terracottaColor,
  easternBlue: colors.$easternBlue,
  concrete: colors.$concreteColor,
  alabaster: colors.$alabasterColor,
  gallery: colors.$galleryColor,
  linkWater: colors.$linkWaterColor,
  pacificBlue: colors.$pacificBlueColor,
  lochinvar: colors.$lochinvarColor,
  lightGrey: colors.$lightGreyColor,
  silver: colors.$silverColor,
  monsoonColor: colors.$monsoonColor,
  blueIvy: colors.$blueIvy,
  coolGrey: colors.$coolGrey,
  sunsetOrange: colors.$sunsetOrangeColor,
  fuzzyWuzzyBrown: colors.$fuzzyWuzzyBrown,
  wedgeWood: colors.$wedgeWood,
  notesText: colors.$notesTextColor,
  plantation: colors.$plantationColor,
  ironsideGrey: colors.$ironsideGreyColor,
  ironsideGreyColor: colors.$ironsideGreyColor,
  starDust: colors.$starDust,
  boulder: colors.$boulder,
  carbonGrey: colors.$carbonGrey,
);
@each $textColorName, $textColor in $text-colors {
  @for $i from 1 through 9 {
    .text-#{$textColorName}-opacity-0-#{$i}0 {
      color: rgba($textColor, $i * 0.1);
      @extend .text;
    }
  }
  .text-#{$textColorName} {
    color: $textColor;
    @extend .text;
  }
}
@each $textColorName, $textColor in $text-colors {
  .text-#{$textColorName}-important {
    color: $textColor !important;
    @extend .text;
  }
}

// align
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

// text transform
.none-text-transform {
  text-transform: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.text-underline {
  text-decoration: underline;
}

// text case
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.break-all {
  word-break: break-all;
}
.break-word {
  word-break: break-word;
}
.no-wrap {
  white-space: nowrap;
}
.pre-wrap {
  white-space: pre-wrap;
}

// text-size
// Usage className="text-{fontSize}"
// Example: className="text-xs text-28 text-22-important text-20-on-md-screen"
$standart-text-size: (
  xs: 12px,
  sm: 14px,
  md: 16px,
  lg: 18px,
  xl: 24px,
  xxl: 36px,
);

$non-standart-text-size: (9, 10, 11, 13, 15, 20, 22, 25, 26, 28, 30, 32, 34, 40, 42, 44, 46, 50, 100);

@mixin createScreenText($textPrefix, $args...) {
  @each $screenSize, $screenSizeValue in screenSize.$screen-sizes {
    .#{$textPrefix}-on-#{$screenSize}-screen {
      @media(max-width: $screenSizeValue) {
        @content;
      }
    }
  }
}

@each $textSizeName, $textSize in $standart-text-size {
  .text-#{$textSizeName} {
    font-size: $textSize;
  }
  .text-#{$textSizeName}-important {
    font-size: $textSize !important;
  }

  @include createScreenText('text' + '-' + $textSizeName) {
    font-size: $textSize;
  }
}

@each $textSize in $non-standart-text-size {
  .text-#{$textSize} {
    font-size: #{$textSize}px;
  }
  .text-#{$textSize}-important {
    font-size: #{$textSize}px !important;
  }

  @include createScreenText('text' + '-' + $textSize) {
    font-size: $textSize;
  }
}

// Usage className="text-weight-{textWeight}"
// Example: className="text-weight-100 text-weight-normal-important text-weight-bold-on-md-screen"
$font-weight-sizes: (
  '100': 100,
  '200': 200,
  '300': 300,
  'normal': 400,
  '500': 500,
  '600': 600,
  'bold': 700,
  '800': 800,
  '900': 900,
);

@each $fontWeightName, $fontWeightSize  in $font-weight-sizes {
  .text-weight-#{$fontWeightName} {
    font-weight: $fontWeightSize;
  }
  .text-weight-#{$fontWeightName}-important {
    font-weight: $fontWeightSize !important;
  }
  @include createScreenText('text-weight' + '-' + $fontWeightName) {
    font-weight: $fontWeightSize;
  }
}

// letter-spacing
// Usage className="letter-{letterSpacing}"
// Example: className="letter-xs letter-xs-important letter-xs-on-md-screen"
$standart-letter-spacing: (
  normal: normal,
  xxs: 0.15px,
  xs: 0.25px,
  sm: 0.5px,
  md: 0.75px,
  lg: 1px,
  xl: 1.2px,
  xxl: 1.5px,
);

@each $letterSpacingName, $letterSpacing  in $standart-letter-spacing {
  .letter-#{$letterSpacingName} {
    letter-spacing: $letterSpacing;
  }
  .letter-#{$letterSpacingName}-important {
    letter-spacing: $letterSpacing !important;
  }

  @include createScreenText('letter' + '-' + $letterSpacingName) {
    letter-spacing: $letterSpacing;
  }
}

.text-Outfit {
  font-family: 'Outfit', sans-serif;
}

.text-whisper {
  font-family: 'Whisper', cursive;
}
