@use 'src/Variable/_variable.scss' as colors;

.drive-thumbnail,
.drive-thumbnail-iframe {
  width: 100%;
  height: 100%;
  min-height: 523px;
}

.drive-thumbnail-iframe {
  resize: both;
  overflow: auto;
  border: 1px solid colors.$replyTextColor;
  box-sizing: border-box;
  border-radius: 5px;
}
