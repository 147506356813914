@use 'src/styles/screen_size.scss' as screenSize;
@use 'src/Variable/index' as styles;
@use 'src/styles/rem.scss' as rescale;

.navigation__button {
  height: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: styles.$blackColor;
  padding-bottom: 10px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.tab__active {
  border-bottom: 2px solid styles.$blackColor;
}
