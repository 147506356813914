@use 'Variable/_variable.scss' as colors;

.embedded-panel-wrapper {
  max-height: 100%;
  overflow: hidden;
  border-left: 2px solid rgba(colors.$blackColor, 0.08);

  &.show {
    width: 100%;
  }
}

.header {
  border-bottom: 1px solid colors.$dawnPink;
}

.content {
  height: calc(100% - 53px);
  overflow: auto;
}
