@use "src/Variable" as styles;

.nav-item {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: styles.$projectThumbnailColor;
  opacity: 0.8;
  &:hover {
    color: styles.$easternBlue;
  }
}

.account-dropdown-trigger {
  &:hover {
    .nav-item {
      color: styles.$easternBlue !important;
    }
  }
}

.dropdown-option {
  width: 182px;
  padding: 4px;
  cursor: pointer;
  color: styles.$projectThumbnailColor;
  background: transparent;
  border: none;
  text-align: left;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
}

.logout-option {
  color: styles.$rossoCorsa;
}

.user-avatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  display: block;
}

.angle-down-icon {
  color: styles.$projectThumbnailColor;
  opacity: 0.8;
}

.dropdown-wrapper {
  z-index: 100000;

  :global(.ant-dropdown-menu-title-content) {
    text-align: left !important;
  }
  :global(.ant-dropdown-menu-item) {
    height: 40px !important;
  }
}