@use 'src/styles/screen_size.scss' as screenSize;
@use 'src/Variable/index' as styles;
@use 'src/styles/rem.scss' as rescale;

.navigation__button {
  height: 42px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.25px;
  color: styles.$blackColor;
  margin-right: 48px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-transform: capitalize;
}

.tab__active {
  border-bottom: 4px solid styles.$blackColor;
}
