@use 'src/Variable' as styles;

.connect__button {
  height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 18px;
  gap: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  .button__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: black;
  }

  .connect__icon {
    color: styles.$notificationDateColor;
    opacity: 0.6;
  }
}

.status {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: styles.$notificationDateColor;
}
