@use 'Variable/_variable.scss' as colors;

.top-zoom-tools-wrapper {
  position: absolute;
  border-radius: 4px;
  overflow: hidden;
  right: 20px;

  a,
  button {
    border: none;
    background-color: rgba(colors.$blackColor, 0.65);
    width: 40px;
    height: 40px;
    transition: all 0.3s;

    &[disabled] {
      cursor: auto;
    }
    &:not([disabled]):hover {
      background-color: colors.$blackColor;
      color: colors.$whiteColor;
    }
  }
}
.top-zoom-tools-wrapper{
  top: 20px;
}

.download-in-progress {
  position: relative;

  &:after {
    content: '';
    width: 70%;
    height: 70%;
    border: 1px solid transparent;
    border-right-color: colors.$whiteColor;
    position: absolute;
    top: 15%;
    left: 15%;
    border-radius: 100%;
    animation: spin 2s infinite linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
