@use 'Variable/_variable.scss' as colors;

:export {
  defaultTextareaBackgroundColor: colors.$concreteColor,
}

.message-form {
  position: relative;
}

.textarea-wrapper {
  :global(.ant-form-item-explain) {
    position: absolute;
  }
}

.textarea {
  border: none !important;
  border-radius: 12px;
  padding: 8px 48px 8px 16px !important;

  :global(.ant-form-item-explain) {
    display: none;
  }

  &:global(.ant-form-item-has-error) {
    .label,
    .textarea,
    &:after {
      color: colors.$errorColor !important;
      border-color: colors.$errorColor !important;
    }
  }
}

.send-message-button {
  border: none;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 24px !important;
  height: 24px;
  box-shadow: none;
  top: 9px;
  right: 9px;
  background-color: colors.$tradeWind;

  &[disabled] {
    background: colors.$cloudColor;
    color: colors.$whiteColor;
  }
}
