@use 'Variable/_variable.scss' as colors;

.summary {
  background-color: colors.$galleryColor;
  border-radius: 10px;
}

.left-vertiacl-divider {
  border-left: 1px solid colors.$notesTextColor;
}

.prepared-title {
  color: colors.$milestoneStepTextColor;
}

.prepared-address {
  color: colors.$notesTextColor;
}
