@use 'src/Variable' as styles;

.empty-project-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: styles.$whiteColor;
  height: 100%;
  border-radius: 8px;

  .starter-screen-img {
    width: 100%;
    height: 250px;
    background: linear-gradient(180deg, styles.$loblolly 0%, styles.$loblolly 78.05%, rgba(236, 247, 249, 0.00) 100%);
    border-radius: 8px;
  }

  .starter-screen-content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .sync-comments-title {
      margin-bottom: 12px;
      color: styles.$blackColor;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;
      text-align: center;
    }

    .sync-comments-description {
      color: styles.$blackColor;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.5px;
    }

    .source-options-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 24px;
      gap: 20px;

      .source-option {
        display: flex;
        padding: 16px 12px;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 104px;
        flex-shrink: 0;
        cursor: pointer;

        border-radius: 8px;
        border: 1px solid styles.$mercury;
        background: styles.$whiteColor;
        box-shadow: 0px 2px 4px 0px rgba(styles.$blackColor, 0.05);
        transition: all 0.3s ease;

        .icons-container {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .source-option-title {
          color: styles.$blackColor;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
        }

        &:hover {
          transform: scale(1.05);
          box-shadow: 0px 4px 8px 0px rgba(styles.$blackColor, 0.1);
        }
      }
    }

    .divider-container {
      display: flex;
      justify-content: center;
      position: relative;
      margin: 20px 0;
      border-bottom: 0.5px solid rgba(styles.$blackColor, 0.08);

      .divider-text {
        position: absolute;
        color: rgba(styles.$blackColor, 0.50);
        margin: auto;
        top: 0;
        bottom: 0;
        text-align: center;
        background: styles.$whiteColor;
        height: 18px;
        width: 34px;
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        letter-spacing: 0.24px;
      }
    }

    .create-task-button {
      display: flex;
      height: 30px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 4px;
      box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.04);

      text-align: center;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      letter-spacing: 1.25px;
    }
  }
}

.manage-files-button {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;

  font-size: 12px;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.12px;
}
