@use 'src/Variable/_variable.scss' as colors;

.custom-react-quill-wrapper {
  position: relative;
}

.user-mention {
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: colors.$easternBlue;
  font-weight: 600;
}

.custom-react-quill {
  :global(.ql-toolbar) :global(.ql-formats) *:not(:global(.ql-color-picker *)):not(:global(.ql-active *)) {
    stroke: colors.$ironsideGreyColor !important;
  }

  :global(.ql-editor) {
    word-break: break-word;
  }

  li {
    padding-left: 0 !important;

    &:before {
      content: none !important;
    }
  }
  ul li {
    list-style-type: disc !important;
  }
  ol li {
    list-style-type: decimal !important;
  }

  :global(.ql-color-label) {
    opacity: 1;
  }

  :global(.ql-formats) button,
  :global(.ql-formats) span:global(.ql-picker-label) {
    margin-right: 5px;

    &:global(.ql-active) {
      background-color: colors.$dawnPink;
    }
  }

  :global(.mention) {
    @extend .user-mention;
  }
  :global(.ql-mention-list-item) {
    display: flex;
    align-items: center;
    padding: 12px 8px;
    line-height: normal;
  }

  &.with-mention-button :global(.ql-editor) {
    padding-right: 75px;
  }
}

.mention-dropdown-item {
  .avatar {
    border-radius: 100%;
  }
}

.mention-button {
  position: absolute;
  top: 11px;
  right: 16px;

  .mention-icon path {
    fill: rgba(colors.$blackColor, 0.3);
    transition: all 0.2s;
  }

  &:hover .mention-icon path {
    fill: rgba(colors.$blackColor, 0.8);
  }
}

.loom-icon-btn {
  position: absolute;
  top: 15px;
  right: 45px;
  vertical-align: middle;
  background: transparent;
  border: none;
  cursor: pointer;

  .loom-icon path {
    fill: rgba(colors.$blackColor, 0.3);
    transition: all 0.2s;
  }

  &:hover .loom-icon path {
    fill: rgba(colors.$cornflowerBlue, 0.8);
  }
}

.attachment-icon-btn {
  width: 10px !important;
  height: 18px !important;

  position: absolute !important;
  top: 15px;
  right: 75px;
  vertical-align: middle;
  background: transparent;
  border: none !important;
  cursor: pointer;

  .attachment-icon path {
    fill: rgba(colors.$blackColor, 0.3);
    transition: all 0.2s;
  }

  &:hover .attachment-icon path {
    fill: rgba(colors.$lochinvarColor, 0.8);
  }
}

.auto-link {
  padding-right: 5px;
  position: relative;

  &:after {
    $width: 0.5rem;

    content: '';
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border: 1px solid #1593A0;
    border-left: 1px solid transparent;
    border-radius: 50%;
    margin-left: 5px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.local-image {
  $border-radius: 8px;

  max-width: 100%;
  position: relative;

  .delete-img-btn {
    position: absolute;
    right: -10px;
    top: -10px;
    background: colors.$carbonGrey;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    &:hover {
      background: colors.$fuzzyWuzzyBrown;
    }
  }
  .close-icon {
    height: 10px;
    width: 10px;
    color: colors.$whiteColor;
  }

  &.loading-wrapper img {
    filter: blur(2px) grayscale(100%);
  }

  &.big-image img {
    border: 2px solid colors.$rossoCorsa;
  }

  .big-image-alert {
    background-color: colors.$rossoCorsa;
    color: colors.$whiteColor;
    border-radius: $border-radius $border-radius 0 0;
    padding: 0 10px 0;
  }

  .infinity-loading,
  .progress-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .progress-loading {
    :global(.ant-progress-text) {
      color: colors.$easternBlue;
      text-shadow: colors.$whiteColor 0 0 2px;
    }
    :global(.ant-progress-circle-path) {
      stroke: colors.$easternBlue;
    }
  }

  .inner-local-image,
  img {
    width: 100px;
    height: 100px;
    border-radius: $border-radius;
    object-fit: cover;
  }
  .image-container {
    position: relative;
  }
}
.images-list-wrapper {
  gap: 12px;
}
