@use 'Variable/_variable.scss' as colors;

.auto-profile-photo,
.profile-photo {
  $size: 111px;
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
  border-radius: 10px;
}

.auto-profile-photo {
  background-color: colors.$monsoonColor;
  font-weight: 900;
  font-size: 50px;
  line-height: 51px;
  text-align: center;
  letter-spacing: 0.25px;
  color: colors.$whiteColor;

  .separator {
    width: 60px;
    height: 2px;
    background-color: colors.$whiteColor;
    margin: 0 auto;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      border: 2px solid colors.$whiteColor;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      left: -5px;
      background-color: colors.$monsoonColor;
    }
    &:after {
      right: -5px;
      background-color: colors.$whiteColor;
    }
  }
}
