@use 'src/Variable' as styles;

$inputConatinerWidth: 508px;
$nameInputWidth: 244px;

.container {
  width: 100%;
  border-top: 2px solid styles.$dawnPink;

  :global(.ant-input) {
    border: 1px solid styles.$lavenderPinocchio;
  }

  :global(.ant-form-item-control-input) {
    min-height: 0px;
  }

  :global(.ant-select) {
    width: $inputConatinerWidth;
    border-radius: 3px;
    border: 1px solid styles.$lavenderPinocchio;
  }

  :global(.ant-select-selector) {
    width: 100%;
    padding: 6px 11px !important;
    height: 42px !important;
  }

  :global(.ant-select-selection-item) {
    width: $inputConatinerWidth !important;
    font-size: 18px !important;
    font-weight: 300 !important;
    line-height: 30px !important;
    color: rgba(0, 0, 0, 0.16) !important;
  }

  :global(.ant-btn[disabled]){
    background: none !important;
  }
}

.section__heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.25px;
  color: styles.$projectThumbnailColor;
}

.section__description {
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  opacity: 0.6;
}

.progress {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  opacity: 0.6;
}

.name__section,
.email__section,
.role__section,
.photo__section,
.logo__section ,
.buttons__section {
  border-top: 1px solid styles.$dawnPink;
}

.section__container{
  display: flex;
  margin: 20px 0px;
  .title__section {
    display: flex;
    flex-direction: column;
    width: 280px;
    margin-right: 48px;
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
    .description {
      margin-top: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: styles.$dovegrey;
    }
  }
}

.input_field {
  color: black;
}

.name__input {
  width: $nameInputWidth;
}

.email__input {
  width: $inputConatinerWidth;
}

.photo__image,
.logo__image {
  width: 64px;
  height: 64px;
  border-radius: 12px;
  margin-bottom: 13px;
  object-fit: cover;
}

.delete__button, .upload__button {
  display: flex;
  padding: 0px;
  border: none;
  font-size: 14px;
  text-align: start;
  cursor: pointer;
  font-weight: 500;
  line-height: 20px;
  height: fit-content;
  box-shadow: none;
  color: styles.$easternBlue;
  background-color: styles.$alabasterColor;
  &:hover {
    background-color: styles.$alabasterColor;
  }
}

.delete__button {
  margin-left: 36px;
  margin-right: 16px;
  color: styles.$notificationDateColor;
}

.cancel__button {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
