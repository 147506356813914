@use 'src/Variable/_variable.scss' as colors;
@use 'src/styles/z_index.scss' as zIndexSize;

$sidebar-width: 72px;

.sidebar {
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: zIndexSize.$fourth-layer;
  width: $sidebar-width;

  background-color: colors.$hippieBlue;
}

.sidebar__projectListWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;

  .project-link:last-child {
    margin-bottom: 5px;
  }
}

.top-buttons {
  width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  margin-bottom: 30px;
}

.logo {
  width: 48px;
  border-radius: 3px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: colors.$whiteColor;
}

.project-link {
  width: 30px;
  min-height: 30px;
  color: colors.$blueColor;
  background-color: colors.$yellowColor;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;

  &.selected {
    outline-offset: 2px;
    outline: 3px solid colors.$whiteColor;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
