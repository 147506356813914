@use 'Variable/_variable.scss' as colors;

.design-review-table {
  margin-top: 20px;
}

.review-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 6px;
  border: 1px solid colors.$greenWhiteColor;
}

.image-placeholder {
  width: 280px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: colors.$whiteSmoke;
  border-radius: 6px;
  border: 1px solid colors.$lightGrayColor;
}

.reviewer-avatars {
  display: flex;
  gap: 4px;
  align-items: center;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid colors.$lightGrayColor;
  cursor: pointer;
}

.not-labeled {
  color: colors.$monsoonColor;
  font-style: italic;
}

.view-details-button {
  color: colors.$whiteColor;
  background-color: colors.$easternBlue;
  border-radius: 5px;
  padding: 8px 10px;
  border: 0;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    background-color: colors.$mosqueColor;
  }
}

.assigned-users-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  max-width: 100%;
  overflow: hidden;
}

.assign-icon {
  font-size: 1.5rem;
  color: colors.$contractSigningModalColor;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: colors.$ironsideGreyColor;
  }
}

.dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(colors.$blackColor, 0.1);
  background-color: colors.$whiteColor;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: colors.$whiteSmoke;
  }
}

.dropdown-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
}