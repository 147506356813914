@use 'Variable/variable' as vars;

$buttonShadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
$labelBg: rgba(73, 148, 153, 0.12);
$colorTableText: rgba(0, 0, 0, 0.4);

.projects__withdrawContainer {
  width: 100% !important;
  float: right;
}

.projects__payment-column {
  overflow: auto;
  height: calc(100vh - 125px);
  padding-bottom: 32px;
}
