@use 'Variable/_variable.scss' as colors;

.payment-history-table {
  th:global(.ant-table-cell),
  td:global(.ant-table-cell) {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.15px;
  }
  th:global(.ant-table-cell) {
    background-color: transparent;
    color: rgba(colors.$blackColor, 0.4);

    &:before {
      display: none;
    }
  }
  td:global(.ant-table-cell) {
    color: rgba(0, 0, 0, 0.6);
  }
  :global(.ant-table-cell) {
    border: none;
  }
  :global(.ant-table-row) {
    transition: all 0.3s;

    &:hover {
      :global(.ant-table-cell) {
        background: #F6F9F9;
      }
      box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
      border-radius: 4px;

      .remove-button {
        opacity: 1;
      }
    }
  }

  .remove-button {
    opacity: 0;
    transition: all 0.3s;
  }
}

.amount-width {
  width: 15%;
}
.payment-status-width {
  width: 20%;
}
.total-width {
  width: 15%;
}
.platform-fee-width {
  width: 15%;
}
.stripe-fee-width {
  width: 15%;
}
.remove-width {
  width: 5%;
}
