@import '../../Variable/variable';

.autofill__title{
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.25px;
  color: $projectThumbnailColor;
  padding: 10px 0px 30px 0px;
}

.autofill__modalText{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: $notificationDateColor;
  padding: 0px 42px;
}

.autofill__checkWrap{
  text-align: center;
  padding: 22px 0px;
  font-weight: 500;
  font-size: 12px;
}

.autofill__button{
  padding: 0px 30px;
  box-shadow: 0px 0px 1px rgb(40 41 61 / 8%), 0px 0.5px 2px rgb(96 97 112 / 16%);
  border-radius: 50px;
  top: -14px;
  font-weight: 500;
}

.autofill__okButton {
  background: $easternBlue;
}

.autofill__cancelButton {
  background: transparent;
  color: $lochinvarColor;
  border: 0;
  box-shadow: none;
}
