@use 'src/Variable' as styles;

.collaboratorAvatar {
  position: relative;
  background: styles.$whiteColor;
  border-radius: 50%;
  overflow: hidden;
  height: 28px;
  width: 28px;
  display: block;
}

.gridContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  justify-items: center;
  align-items: center;
  .collaboratorDetail{
    display: flex;
    justify-self: start;
    align-items: center;
  }
}

.inviteStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 103px;
}

.image-container {
  width: 16px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
  .remove-icon-btn {
    font-size: 20px;
    &:hover {
      color: styles.$fuzzyWuzzyBrown !important;
    }
  }
  .invite-icon-btn {
    font-size: 20px;
    min-width: 20px;
    &:hover {
      color: styles.$easternBlue !important;
    }
  }
}
