@use 'Variable/_variable.scss' as colors;
@use 'styles/z_index.scss' as zIndexSize;

$comments-block-width: 290px;

.comments-block-wrapper {
  position: absolute;
  cursor: auto;
  transform: translate(0px, -57px);
  z-index: zIndexSize.$first-layer;
  transition: opacity 0.3s;

  &:not(&.show-comments-list) {
    .comments-block {
      display: none;
    }
  }
  &.show-comments-list {
    z-index: zIndexSize.$second-layer;

    .comment-marker svg path {
      fill: colors.$pacificBlueColor !important;
    }
  }

  &.show-comments-list-left .comments-block {
    right: 60px;
    left: auto;
    top: 8px;
  }

  &:hover {
    opacity: 1;
  }

  &.is-dragging {
    transform: translate(0, 0);
  }
}

.comments-block {
  background: colors.$linkWaterColor;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  width: $comments-block-width;
  padding: 15px 10px;
  max-height: 500px;
  overflow: auto;
  position: absolute;
  left: 60px;
  top: 8px;

  &.empty-comments-block {
    overflow: hidden;
    padding: 0;
    margin-top: 7px;
  }
}

.avatar,
.medium-avatar,
.marker-avatar {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.medium-avatar {
  width: 36px;
  height: 36px;
}

.marker-avatar {
  width: 44px;
  height: 44px;
  position: absolute;
  top: 12px;
  left: 2px;
}
