@use 'Variable/_variable.scss' as colors;
@use 'src/styles/z_index.scss' as zIndexSize;

$item-card-width: 265px;

.item-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 265px);
  grid-auto-rows: 10px;
  grid-column-gap: 30px;
  justify-content: center;
  background: colors.$milkWhite;
  border-radius: 12px;
}

.item-card {
  width: $item-card-width;
  overflow: hidden;
  position: relative;

  .image-wrapper {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 12px;
      z-index: zIndexSize.$first-layer;
      background-color: rgba(colors.$blackColor, 0.5);
      color: colors.$whiteColor;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.item-card-downloading:after {
      content: 'Downloading ...';
    }
    &.item-card-removing:after {
      content: 'Removing ...';
    }
  }

  .image {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    border-radius: 8px;
  }

  .remove-image-button {
    opacity: 0;
    transition: all 0.2s;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: zIndexSize.$background;
  }

  &:not(.item-card-downloading):not(.item-card-removing):hover {
    .action-dropdown-button,
    .add-reaction-button {
      opacity: 1;
    }
  }

  .action-dropdown {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }

  .action-dropdown-button,
  .reaction-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: colors.$whiteColor;
    border: 1px solid colors.$greenWhiteColor;
    border-radius: 40px;
    z-index: zIndexSize.$first-layer;
  }

  .action-dropdown-button {
    width: 20px;
    height: 28px;
    opacity: 0;
  }

  .reaction-button {
    height: 28px;
    color: colors.$carbonGrey;
    transition: all 0.4s;

    &.active-reaction-button {
      border-color: colors.$easternBlue;
      background-color: colors.$tradeWind;
      color: colors.$whiteColor;
    }
  }

  .add-reaction-button {
    width: 45px;
    height: 28px;
    position: absolute;
    bottom: 12px;
    right: 44px;
    opacity: 0;
  }

  .reaction-list {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 100px);
    max-height: 100%;
    overflow: auto;
    padding: 12px 0 12px 12px;

    .reaction-button:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.moodboard-text-border {
  padding: 24px;
  border: 1px solid rgba(colors.$blackColor, 0.1);
  border-radius: 8px;
  background: colors.$whiteColor;

  &.hide-moodboard-text {
    position: absolute;
    z-index: zIndexSize.$background;
    width: $item-card-width;
  }
}

.moodboard-text {
  font-size: 18px;
  line-height: 20px;
  color: colors.$carbonGrey;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 15;
  overflow: hidden;
  margin: 0;

  p {
    margin: 0;
  }
}

.white-spin {
  :global(.ant-spin-dot-item) {
    background-color: colors.$whiteColor;
  }
}
