@use 'Variable/_variable.scss' as colors;

.designer-without-stripe-block {
  background-color: colors.$dullOrangeColor;

  .add-stripe-account-button {
    height: auto;

    &:hover,
    &:active,
    &:focus {
      border-color: colors.$whiteColor;
      color: colors.$whiteColor;
    }
    &:hover {
      background-color: colors.$whiteColor;
      color: colors.$dullOrangeColor;
    }
  }
}
