@use 'src/Variable/_variable.scss' as styles;

.back__button {
  background: none;
  border: none;
  cursor: pointer;
}

.expert__avatar {
  margin-top: 10px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.expert__subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 4px 0 0 0;
  color: styles.$carbonGrey;
}

.expert__added{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  line-height: 16px;
  text-align: center;
  padding: 8px 12px 8px 8px;
  color: styles.$tarawera;
  background: styles.$porcelain;
  border-radius: 8px;
}

.remove__user{
  cursor : pointer;
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  border: none;
  height: fit-content;
  .remove{
    margin-bottom: 0;
    color: styles.$tarawera;
    text-decoration: underline;
    text-decoration-color: styles.$tarawera;
  }
}

.call__button {
  width: 100%;
  margin-top: 20px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.25px;
  border-radius: 8px;
}
