@use 'Variable/_variable.scss' as colors;

.input-wrapper {
  $mainColor: rgba(colors.$blackColor, 0.38);

  position: relative;

  :global(.ant-input-number-affix-wrapper) {
    width: 120px;
    color: $mainColor;
    border: 2px solid rgba(colors.$blackColor, 0.38);
    border-width: 2px !important; // overwriting antd styles
    border-radius: 4px;
    box-shadow: none !important;
    &:not(:global(.ant-input-number-affix-wrapper-status-error)) {
      border-color: $mainColor !important; // overwriting antd styles
    }
  }
  :global(.ant-input-number-prefix) {
    margin-right: 0;
  }
  :global(.ant-form-item-label) {
    position: absolute;
    top: -12px;
    left: 10px;
    z-index: 100;
    background-color: colors.$whiteColor;
    padding: 0 4px;

    label {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.25px;
      color: rgba(colors.$blackColor, 0.38);
      height: auto;
      margin: 0;

      &:after{
        content: none;
      }
    }
  }
  :global(.ant-input-number-input) {
    color: $mainColor;
  }
  :global(.ant-form-item-explain-error) {
    position: absolute;
    white-space: nowrap;
    margin-top: 5px;
  }
}
