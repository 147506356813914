@use 'src/Variable' as styles;

.assignee-bar {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  background: none;

  .user-icon {
    height: 24px;
    border-radius: 12px;
    object-fit: cover;
  }

  .user-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .name {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */

      .you {
        color: styles.$gray;
      }
    }

    .email {
      color: styles.$osloGray;
      font-size: 10px;
      font-weight: 400;
    }

    .bigger-font {
      font-size: 12px;
    }
  }

  .task-count {
    color: styles.$gray;
    font-size: 10px;
    font-weight: 400;
    margin-left: auto;
  }

  .check-container {
    width: 14px;

    .check-icon {
      color: styles.$easternBlue;
    }
  }

  .margin-left {
    margin-left: auto;
  }

  .close-icon {
    color: styles.$gray;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .pending {
    color: styles.$ironsideGreyColor;
    font-size: 10px;
    font-weight: 400;
    margin-left: auto;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  p {
    margin-bottom: 0;
  }
}

.selected-assignee {
  background-color: styles.$athensGray;
}
