@use 'Variable/_variable.scss' as colors;
@use 'styles/rem.scss' as *;

$main-text-color: colors.$ironsideGreyColor;

.notes-wrapper {
  height: 100%;
  position: relative;
  color: $main-text-color;

  * {
    color: inherit;
  }
}

.progress-bar {
  position: absolute;
  top: 23px;
  right: 15px;
  color: colors.$monsoonColor;
}

.old-note-info {
  width: 100%;
  line-height: 30px;
  position: absolute;
  top: 40px;
}

.reload-note-button span {
  color: colors.$blueIvy;
  text-decoration: underline;
}

.custom-react-quill {
  height: 100%;

  :global(.ql-toolbar),
  :global(.ql-container),
  :global(.ql-editor) {
    border: none;
    padding: 0;
  }

  :global(.ql-toolbar) {
    border-bottom: 1px solid colors.$dawnPink;
    padding: 20px 15px;
  }

  :global(.ql-container) {
    padding: 20px 15px;

    font-size: px-to-rem(20px);
    line-height: 30px;
    letter-spacing: 0.25px;
    word-break: break-word;

    height: calc(100% - 24px - 40px);

    :global(.ql-editor)::before {
      left: 15px;
      font-style: normal;
      font-size: px-to-rem(16px);
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  &.show-old-note-info :global(.ql-container) {
    margin-top: 90px;
  }
}

.height-100percent {
  height: calc(100% - 1px);
}

.mention-button {
  top: 19px;
  left: 215px;
  right: auto;
}
