@use 'src/Variable/index' as styles;
@use 'src/styles/z_index.scss' as zIndexSize;
@use 'src/styles/rem.scss' as rescale;

$thumbnailBorderRadius: 10px;
$avatarSize: 24px;
$maxCollaboratorsListWidth: 100px;

:export {
  avatarSize: $avatarSize;
  maxCollaboratorsListWidth: $maxCollaboratorsListWidth;
}

.project-card-menu {
  box-shadow: 0 0 2px rgba(40, 41, 61, 0.04), 0 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
}

.thumbnail {
  position: relative;
  height: 301px;
  cursor: pointer;
}


.thumbnail__menuButton {
  width: 100%;
  background-color: transparent;
  padding: 3px 4px 3px 0;
  gap: 12px;
  border: none;
  cursor: pointer;
  @include styles.flexDisplay(flex, flex-start, center);

  .thumbnail__menuIcon {
    height: 16px;
    width: 16px;
  }

  .thumbnail__menuText {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: styles.$projectThumbnailColor
  }
}

.danger-color {
  color: styles.$fuzzyWuzzyBrown;

  .thumbnail__menuText {
    color: styles.$fuzzyWuzzyBrown;
  }
}
.project-thumbnail-container {
  border: none;
  cursor: pointer;
  &:hover + .thumbnail__projectContainer .project-name-container {
    text-decoration: underline;
    text-decoration-color: styles.$blackColor;
  }
}

.thumbnail__button {
  @include styles.flexDisplay(flex, space-between, inherit);
  flex-direction: column;
  border: 1px solid styles.$alto;
  border-radius: $thumbnailBorderRadius;
  overflow: hidden;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
}
.thumbnail__image {
  @extend .imageStyle;
  width: 256px;
  height: 155px;
}
.project-name-container {
  border: none;
  text-align: start;
  background-color: styles.$linkWaterColor;
  padding: 16px 16px 24px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-color: styles.$blackColor;
  }
}
.project-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.25px;
  color: styles.$blackColor;
}
.last-edited {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: styles.$carbonGrey;
}

.letters-icon {
  text-align: center;
  overflow: hidden;
  background-color: styles.$galleryColor;
  img {
    width: 100%;
    height: 153px;
  }
}

.thumbnail__projectContainer {
  display: flex;
  flex-direction: column;
  height: 146px;
  position: relative;
  background-color: styles.$linkWaterColor;
  width: 100%;
  border-radius: 0 0 $thumbnailBorderRadius $thumbnailBorderRadius;
}

.thumbnail__threeDots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 20px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid styles.$greenWhiteColor;
  border-radius: 40px;
  text-align: center;
  &:hover {
    border: 1px solid styles.$projectThumbnailColor;
  }
  img {
    height: 15px;
    width: 5px;
  }
}

.thumbnail__metaDetails {
  @include styles.flexDisplay(flex, flex-start, flex-start);
  flex-direction: column;
  margin: rescale.px-to-rem(0px) rescale.px-to-rem(8px);
  .thumbnail__lastAccess {
    color: styles.$ironsideGreyColor;
    span {
      font-size: rescale.px-to-rem(12px);
    }
  }
  .thumbnail__avatars {
    display: flex;
  }
}
.avatar {
  height: $avatarSize;
  width: $avatarSize;
  border-radius: 50%;
  margin-right: -10px;
  background: styles.$whiteColor;

  border: 1.5px solid styles.$whiteColor;
  transition: all 0.3s;

  &:last-child {
    margin-right: 0;
  }
}

.avatar-button {
  height: $avatarSize;
  width: $avatarSize;
  border-radius: 50%;

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  color: styles.$carbonGrey;
  background: styles.$whiteSmoke;

  border: 1.5px solid styles.$whiteColor;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: styles.$silver;
  }
}
