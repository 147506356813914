@use 'Variable/_variable.scss' as colors;

.modal {
  :global(.ant-modal-header) {
    border-radius: 12px 12px 0px 0px;
    margin-bottom: 40px;
    padding: 0px;
  }
  :global(.ant-modal-content) {
    border-radius: 12px;
    padding: 20px;
  }
}

.input-area {
  height: 160px;
  background-color: colors.$desertStormColor;
  border-radius: 5px;
  min-width: 300px;
  padding: 0 30px;
}

.signature-input-wrapper {
  :global(.ant-form-item-explain) {
    display: none;
  }

  &:global(.ant-form-item-has-error) {
    .signature-input::placeholder {
      color: colors.$errorColor;
    }
  }
}

.signature-input {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent !important;
}

.signature-preview {
  border: 1px solid colors.$lavenderPinocchio;
  border-radius: 5px;
  height: 160px;
  min-width: 300px;
}
