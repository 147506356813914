@use 'Variable' as styles;

.workspace__sidebarWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.workspace_sidebarCardWrapper {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
}

.new__templateButton {
  @include styles.flexDisplay(flex, center, center);
  color: styles.$easternBlue;
  background: styles.$greenWhiteColor;
  cursor: pointer;
  position: relative;
  padding: 4px;
  width: 28px;
  height: 28px;
  border-radius: 4px;

  & :global(.ant-dropdown) {
    top: 0 !important;
    left: 45px !important;
    max-height: calc(100vh - 130px);
    overflow: auto;

    &::before {
      content: none;
    }
  }
}
