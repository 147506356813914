@use 'src/Variable' as styles;
@use 'src/Pages/ProjectDetails/sub-routes/Board/components/TaskDetailsPopup/styles.module.scss' as taskDetailsPopup;

.filter-button-container {
  display: flex;
  flex-direction: column;

  .filter-button{
    height: 36px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid styles.$lavenderPinocchio;
    background: styles.$whiteColor;

    color: styles.$carbonGrey;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
  }

  .filter-button-active {
    border: 1px solid styles.$easternBlue;
    color: styles.$easternBlue;
    background: linear-gradient(0deg, rgba(21, 147, 160, 0.08) 0%, rgba(21, 147, 160, 0.08) 100%), styles.$whiteColor;
  }
}

.filter-button-container-popover {
  :global(.ant-popover-inner) {
    border-radius: 8px;
    border: 1px solid styles.$lavenderPinocchio;
  }

  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}

.filter-options{
  width: 320px;
  padding: 4px 0px;
  border-radius: 8px;
  background: styles.$whiteColor;
  box-shadow: 0px 4px 6px -2px rgba(16, 16, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  .options-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px;
    color: styles.$carbonGrey;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    border-bottom: 0.5px solid styles.$borderColor;

    .close-icon {
      display: flex;
      width: 16px;
      height: 16px;
      color: styles.$carbonGrey;
      cursor: pointer;
    }
  }

  .categories-container {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }

  .filter-buttons {
    display: flex;
    gap: 8px;

    :global(.ant-btn) {
      padding: 2px 6px;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
    }
  }

  .action-buttons {
    @extend .filter-buttons;
    margin-top: 6px;
    padding: 8px 14px;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid styles.$borderColor;
  }

  @each $priority, $priority-props in taskDetailsPopup.$priority-colors {
    .#{$priority}-priority-circle {
      width: 8px;
      height: 8px;
      margin-right: 4px;
      margin-left: 4px;
      border-radius: 16px;
      background: map-get($priority-props, circle-color);
    }
  }

  .user-image{
    width: 16px;
    height: 16px;
    border-radius: 100px;
  }
}

.arrow-drop-down-icon {
  transition: all 0.3s;

  &.active-arrow-drop-down-icon {
    transform: rotateX(180deg);

    path {
      fill: styles.$easternBlue;
    }
  }
}

.filtered-arrow-drop-down-icon {
  path {
    fill: styles.$easternBlue;
  }
}
