@use 'src/Variable/_variable.scss' as colors;

.heading {
  line-height: 28px;
  letter-spacing: 0.25px;
  color: colors.$projectThumbnailColor;
}

.sub-heading {
  margin-bottom: 12px;
  line-height: 24px;
  letter-spacing: 0.15px;
  opacity: 0.6;
}

.inviteModal {
  :global(.ant-modal-header) {
    border-radius: 12px;
    border: none;
    margin-bottom: 12px;
    padding: 0;
  }
  :global(.ant-modal-content) {
    border-radius: 12px;
    padding: 0 24px 32px 24px;
  }
  :global(.ant-modal-body) {
    padding: 0;
  }
}

.collaboratorInvite {
  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.invite-button {
  height: 33px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.12px;
}

.collaborator-list-wrapper {
  max-height: 400px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.readonly-wrapper {
  background: colors.$whiteSmoke;
  border-top: 1px solid rgba(109, 130, 130, 0.2);
  border-radius: 0 0 12px 12px;
}

.title-wrapper {
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid colors.$primaryButtonBorderColor;
  background: colors.$milkWhite;
  font-size: 20px;
  font-weight: 500;
  line-height: 19.5px; /* 97.5% */
}
