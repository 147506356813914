@use 'src/Variable/index' as styles;

.spinner-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-button-container {
  display: flex;
  align-items: center;
  align-self: center;
  cursor: pointer;
  height: 42px;

  padding: 14px 17.04px 14px 16px;
  gap: 7px;
  border-radius: 8px;
  background: styles.$easternBlue;
  border: 0.71px solid rgba(styles.$blackColor, 0.08);
  box-shadow: 0px 1.4200260639190674px 2.8400521278381348px 0px rgba(96, 97, 112, 0.16);

  .google-icon {
    color: styles.$whiteColor;
  }

  .button-text {
    font-family: Outfit;
    font-size: 12px;
    font-weight: 500;
    line-height: 11.93px;
    letter-spacing: 0.9px;
    text-align: center;
    color: styles.$whiteColor;
  }
}
