@use 'src/Variable/index' as styles;

.project-listing-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
  gap: 44px;

  @media only screen and (max-width: 768px) {
    gap: 24px;
  }
}
