@use '../../Variable' as styles;

.login__navigation {
  @include styles.flexDisplay(flex, space-between, center);
  padding: 0px;
  height: 76px;
  max-width: 1130px;
  margin: 0px auto;

  .login__leftNav {
    margin-left: -4px;
    button {
      @extend .logoStyle;
      border: none;
    }
    img {
      height: 31px;
      object-fit: contain;
    }
  }
}

.TermsAndConditions {
  padding: 2em;
  max-width: 1024px;
  margin: 0 auto;

  li {
    span {
      font-weight: 700;
    }
  }

  p {
    color: black;
    font-weight: 500;
  }

  h1, h2, h3 {
    font-weight: bold;
  }
}

.Header {
  padding: 0em 4em;
}

.footer__links {
  span {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

