@use 'src/Variable' as styles;
@use 'src/styles/z_index.scss' as zIndexSize;

.newProject__modalHeading {
  padding: 22px 32px;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: 0.25px;
  color: styles.$projectThumbnailColor;
  margin-bottom: 0px;
  border-bottom: 1px solid;
  border-color: styles.$primaryButtonBorderColor;
}

.newProject__modal {
  border-radius: 10px;

  :global(.ant-select-selector) {
    box-shadow: none !important;
  }
  :global(.ant-modal-content) {
    border-radius: 12px;
    padding: 0px 0px;
    min-width: 712px;
  }
  :global(.ant-modal-body) {
    padding: 0;
    border-radius: 0 0 8px 8px;
    height: 100%;
  }
  :global(.ant-modal-close-icon) {
    margin: 32px 0px;
    margin-right: 37px;
  }
}

.addProject__thirdScreenContent {
  @extend .modalTimelineScreenDesign;
  @include styles.flexDisplay(flex, flex-start, flex-start);
  flex-direction: column;
  height: 310px;
}

.addProject__transparentButton {
  font-size: 14px;
  margin-right: 32px !important;
  color: styles.$lochinvar;
}

.addProject__primaryModalButton,
.addProject__PrimaryModalButtonDisabled {
  @extend .primaryWorkspaceButtonDesign;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px 24px;
  height: 40px;
}

.addProject__PrimaryModalButtonDisabled {
  background-color: styles.$secondaryDashboardDisabledButtonColor;
  cursor: not-allowed;
}

.addProject__primaryModalButton:hover {
  background-color: styles.$primaryWorkspaceButtonHoverColor;
  color: styles.$whiteColor !important;
}

.addProject__type {
  @include styles.flexDisplay(flex, space-between, center);
  padding-left: 12px;
  border: 1px solid styles.$dashboardModalColor;
  outline: none;
}

.addProject__dropdownBtn,
.addProject__plusBtn {
  @extend .plusButtonStyle;
  padding: 10px;
  border-radius: 0;
}

.addProject__imageUploading {
  @include styles.flexDisplay(flex, flex-start, flex-end);
  .addProject__imageThumbnail {
    height: 80px;
    width: 96px;
    object-fit: contain;
    margin-bottom: 24px;
    margin-right: 16px;
    border: none;
    border-radius: 4px;
  }

  .addProject__uploadButton {
    @extend .transparentWorkspaceButtonDesign;
    padding: 0;
    margin-bottom: 32px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    label {
      font-size: 13px;
      cursor: pointer;
    }

    #upload-photo {
      opacity: 0;
      position: absolute;
      z-index: zIndexSize.$background;
    }
  }
}

.projectTypes__wrapper {
  padding: 0px 32px;
  padding-bottom: 16px;
  .template__heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.25px;
    margin: 24px 0px;
    color: styles.$projectThumbnailColor;
  }
}


// project card css
.projectCard__wrapper {
  gap: 24px 42px;
}

.projectCard {
  max-width: 200px;
  position: relative;
  background: styles.$linkWaterColor;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  border: 1px solid styles.$primaryButtonBorderColor;
  :hover {
    opacity: 1;
  }
}

.projectCard__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: styles.$linkWaterColor;
  transition-duration: 300ms;
  .projectCard__description {
    width: 160px;
    height: 96px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  .projectCard__selectButton {
    width: 100%;
  }
}

.projectName {
  max-width: 50%;
  padding: 56px 32px;
  .projectName__title{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: styles.$projectThumbnailColor;
  }
}

.projectCard__image {
  max-width: 199px;
  height: 160px;
  border-radius: 8px 8px 0 0;
}

.projectCard__radioWrapper {
  border-top: 1px solid;
  border-color: styles.$primaryButtonBorderColor;
  padding: 17px 0 13px 16px;
}

.projectCard__label {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.25px;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.projectCard__active {
  outline: 1px solid styles.$tradeWind;
}

.errorMessage {
  margin-top: 10px;
  color: styles.$sunsetOrangeColor;
}

.modalFooter {
  background: styles.$alabaster;
  border-top: 1px solid styles.$primaryButtonBorderColor;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  .modalFooter__buttons {
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
