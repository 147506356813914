@use 'Variable/index' as styles;
@use 'styles/screen_size.scss' as screenSize;

.projects__withdrawContainer {
  flex: 0.2;
  background: styles.$linkWaterColor;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  width: 20%;
  max-width: 320px;
  @include styles.flexDisplay(flex, space-between, flex-start);
  padding: 16px 24px;
  border-radius: 12px;

  .projects__leftSide {
    @include styles.flexDisplay(flex, space-between, flex-start);
    flex-direction: column;
    span {
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 1.5px;
      color: styles.$notesTextColor;
      font-weight: 500;
    }
    h3 {
      margin: 0;
      letter-spacing: 0.25px;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: styles.$projectThumbnailColor;
    }
  }

  .projects__rightSide {
    button {
      @extend .secondaryNewDesignButton;
      padding: 7px;
      font-size: 13px;
      line-height: 16px;

      &[disabled] {
        color: styles.$coolGrey;
        border-color: styles.$coolGrey;
        background: transparent;
        cursor: auto;
      }
    }
    button:hover:not([disabled]) {
      background-color: styles.$primaryTransparentButtonHoverColor;
    }
  }
}

@media only screen and (max-width: screenSize.$screen-md) {
  .projects__withdrawContainer {
    width: 24%;
    margin-right: 24px;
    padding: 16px;
    .projects__leftSide {
      span {
        font-size: 10px;
      }
      h3 {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: screenSize.$screen-sm) {
  .projects__withdrawContainer {
    margin-right: 20px;
    padding: 10px;
    .projects__leftSide {
      span {
        font-size: 10px;
      }
      h3 {
        font-size: 14px;
      }
    }
    .projects__rightSide {
      button {
        font-size: 10px;
      }
    }
  }
}

@media only screen and (max-width: screenSize.$screen-xs) {
  .projects__withdrawContainer {
    width: 40%;
    margin-right: 24px;
    padding: 12px 12px;
    .projects__leftSide {
      span {
        font-size: 10px;
      }
      h3 {
        font-size: 12px;
      }
    }
  }
}
