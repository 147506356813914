@use 'Variable/_variable.scss' as colors;

$minHeight: calc(100vh - 380px);
$fileContainerHeight: calc(100vh - 310px);

.add-file-wrapper {
  background: colors.$linkWaterColor;
  /* Elevation/Default Cards or Button Pressed */

  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  height: $fileContainerHeight;
  min-height: $minHeight;
}

.add-file-logo {
  height: 100%;
}
