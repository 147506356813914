@use 'src/Variable' as styles;

$column-colors: (
  one: (
    background-color: styles.$vistaWhite,
  ),
  two: (
    background-color: styles.$selago,
  ),
  three: (
    background-color: styles.$blackSqueeze,
  ),
  four: (
    background-color: styles.$porcelainLight,
  )
);
.add-new-column-container {
  display: flex;
  width: 100%;
  height: 88px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background-color: styles.$porcelainLight;
  .add-new-column {
    width: 100%;
    .add-new-column-text {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      color: styles.$nevada;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .input-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .title-input {
        height: fit-content !important;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid styles.$wedgeWood;
        background-color: styles.$whiteColor !important;
        padding-left: 8px;
      }
      .buttons-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        .cancel-button {
          cursor: pointer;
          color: styles.$pacificBlueColor;
          font-variant-numeric: lining-nums tabular-nums;
          font-family: Outfit;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 153.846% */
          letter-spacing: 0.25px;
          border: none;
          background: none;
        }
        .add-button {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          height: 28px;
          width: 60px;
          padding: 2px 16px;
          gap: 8px;
          border-radius: 4px;
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 200% */
          letter-spacing: 0.5px;
        }
        .disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
@each $colNum, $num in $column-colors {
  .#{$colNum}-column {
    background-color: map-get($num, background-color);
  }
}
