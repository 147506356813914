.icon {
  width: 80px;
  height: 80px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal-button {
  height: 40px !important;
  width: 100%;
  max-width: 215px;
}
