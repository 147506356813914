@use 'Variable/_variable.scss' as colors;
@use 'styles/z_index.scss' as zIndexSize;

.function-not-available {
  width: 100%;
  height: 100%;
  position: relative;

  &.disabled-click::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: zIndexSize.$default;
  }
}

.function-not-available-cursor {
  cursor: url('~assets/no_comments.svg') 12 36, auto;
}

.tooltip {
  padding: 16px;
  font-size: 16px;
  background-color: colors.$whiteColor;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
  z-index: zIndexSize.$third-layer;
}
