@use 'Variable/_variable.scss' as colors;

.caption-input {
  padding: 8px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  &,
  &:active,
  &:focus {
    border: 1px solid colors.$lavenderPinocchio;
    box-shadow: none;
  }
}
