@use 'Variable/_variable.scss' as colors;

.home-wrapper {
  padding: 16px; // Adds padding around the main content area
  padding-top: 0;
}

.dashboard-header {
  position: sticky;
  top: 0;
  background-color: colors.$whiteColor;
  padding: 16px 0;
  margin-bottom: 32px;
  z-index: 100;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .header-logo {
    width: 30px;
    height: auto;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    color: #1f2937;
    margin: 0;
    text-align: left;
  }

  .actionButtons {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;

    .unselectButton {
      padding: 8px 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: colors.$whiteSmoke;
      color: colors.$darkCharcoal;
      border: 1px solid colors.$silver;
      border-radius: 6px;
      transition: all 0.2s ease-in-out;
    
      &:hover {
        background-color: colors.$lavenderPinocchio;
        border-color: colors.$lightGrayColor;
      }
    
      &:focus {
        outline: none;
        box-shadow: 0 0 5px rgba(colors.$blackColor, 0.2);
      }
    }
    
    .exportButton {
      background-color: colors.$easternBlue;
      color: colors.$whiteColor;
      padding: 8px 16px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 180px;
    
      &:hover {
        background-color: #107882;
      }
    
      &:disabled {
        background-color: #a0a0a0;
        cursor: not-allowed;
      }
    }
  }
}

.filter-capsules {
  display: flex;
  gap: 12px; // Adds spacing between capsules
  margin-bottom: 24px; // Adds space below the filters and above the cards
  justify-content: center;
}

.filter-button {
  border-radius: 20px;
}

.ant-row {
  row-gap: 24px; // Adds vertical space between rows of cards
  column-gap: 16px; // Adds horizontal space between columns of cards
}

.pagination-wrapper {
  margin-top: 24px;
  margin-bottom: 24px;
}
