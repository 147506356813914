@use 'src/Variable/index' as styles;

.parent-container {
  display: flex;
  flex-direction: column;
  background-color: styles.$alabaster;
  height: 100vh;

  .page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
}