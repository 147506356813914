@use 'src/Variable' as styles;

.kanban-board {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.column {
  flex: 1;
  margin: 10px;
  background: styles.$milkWhite;
  padding: 20px;
  border-radius: 10px;
}

.thumbnail-wrapper {
  position: relative;
  width: 100%;
  height: 170px;
}

.header-loader {
  background: #eee linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shine 1.5s linear infinite;
}

.header-loader {
  height: 45px;
  border-radius: 5px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
