@use 'src/Variable/_variable.scss' as colors;
@use 'src/styles/z_index.scss' as zIndexSize;
@use 'src/styles/screen_size.scss' as screenSize;

.carousel-dots {
  bottom: -55px !important;
  gap: 9px !important;
  li, button {
    width: 12px !important;
    height: 12px !important;
    border-radius: 50% !important;
    background: colors.$easternBlue;
    opacity: 0.2;
    &:hover {
      opacity: 0.8 !important;
    }
  }
  :global(li.slick-active) {
    opacity: 1 !important;
    button {
      background: colors.$easternBlue !important;
      border-radius: 50% !important;
    }
  }
}

.no-comments {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    cursor: url('~assets/no_comments.svg') 12 36, auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &::before {
    content: 'Add comment not supported';
    position: absolute;
    cursor: url('~assets/no_comments.svg') 12 36, auto;
    top: 50%;
    left: 50%;
    width: 200px;
    padding: 16px;
    font-size: 24px;
    background-color: colors.$whiteColor;
    box-shadow: 0px 2px 10px rgba(colors.$blackColor, 0.3);
    border-radius: 8px;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: zIndexSize.$first-layer;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover::before {
    opacity: 1;
  }
}

.files-container-wrapper {
  height: 100%;
  overflow: auto;
}

.iframe {
  width: 100%;
  height: 100%;
  position: relative;
}

.many-iframes {
  max-height: 523px;
}

.file-container-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  :global(.slick-list) {
    height: 100% !important;
  }
}
