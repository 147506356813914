@use 'Variable/_variable.scss' as colors;

.paymentFormModal {
  border-radius: 10px;
  max-width: 900px;

  :global(.ant-modal-body) {
    padding: 0;
    border-radius: 0 0 12px 12px;
    height: 100%;
  }
  :global(.ant-modal-header) {
    border-bottom: none;
    padding: 0;
  }
  :global(.ant-modal-content) {
    border-radius: 12px;
    padding: 16px;
    min-width: 712px;
  }
}

.paymentFormModal__stepButton {
  $activeBorderColor: rgba(colors.$lochinvarColor, 0.8);
  $activeBgColor: rgba(colors.$seaColor, 0.12);

  border: none;
  background-color: transparent;
  border-left: 4px solid colors.$concreteColor;
  width: 100%;

  &.paymentFormModal__stepButtonActive {
    border-color: $activeBorderColor;
    background-color: $activeBgColor;

    .paymentFormModal__stepButton_idx {
      border: none;
      background-color: $activeBorderColor;
      color: colors.$whiteColor;
    }
  }

  &.paymentFormModal__stepButtonDone {
    border-color: colors.$coolGrey;

    .paymentFormModal__stepButton_idx {
      border: none;
      background-color: colors.$coolGrey;
      color: colors.$whiteColor;
    }
  }

  .paymentFormModal__stepButton_idx {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4);
  }
}

.paymentFormModal__paymentDetailsList {
  width: 70%;

  .paymentFormModal__paymentDetailsItemTitle {
    color: rgba(0, 0, 0, 0.4);
  }
  .paymentFormModal__paymentDetailsItemPrice {
    color: rgba(0, 0, 0, 0.6);
  }

  .paymentFormModal__paymentDetailsListItem {
    &:last-child {
      font-size: 20px;

      .paymentFormModal__paymentDetailsItemPrice {
        color: rgba(0, 0, 0, 0.87);
      }
    }

    &:nth-child(even) {
      background-color: colors.$desertStormColor;
    }
  }
}

.paymentFormModal__savedCard {
  border: 1px solid rgb(131 131 131 / 54%);
  border-radius: 4px;
}

.paymentFormModal__scroller {
  overflow-y: scroll;
  max-height: calc(100vh - 300px);
  padding: 20px;
  min-width: 390px;
}
