@use 'src/Variable' as colors;

.icon-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  align-items: center;
  padding-bottom: 16px;

  .icon {
    width: 60px;
    height: 60px;
  }
}
.heading {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.text {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: 0.14px;
  margin: 12px auto;
  max-width: 400px;
}

.button {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.11px;
  margin: 24px auto;
  margin-bottom: 10px;
}
