.icon {
  width: 80px;
  height: auto;
}

.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .center-box {
    display: flex;
    flex-direction: column;
    
    .line {
      width: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}