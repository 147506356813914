@use 'src/Variable/index' as styles;

.parent {
  background-color: styles.$whiteColor;
  display: flex;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(96, 97, 112, 0.12);
  
  .icons {
    display: flex;
    gap: 8px;

    .icon {
      width: 80px;
      height: 80px;
    }
  }

  .modal-content-container {
    display: flex;
    flex-direction: column;
    width: 320px;
    padding: 24px 0px;
    justify-content: center;
    align-items: center;
    margin: 16px 0;

    .modal-title {
      color: styles.$blackColor;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
    }

    .modal-description {
      margin-top: 24px;
      text-align: center;
      font-weight: 400;
      font-style: normal;
      font-family: 'Outfit';
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .modal-button {
    height: 40px !important;
    width: 100%;
  }
}