@use './variable' as *;
@use 'styles/screen_size.scss' as screenSize;
@use 'styles/z_index.scss' as zIndexSize;

.width-full {
  width: 100%;
}

.textDecorationOnClick {
  color: $easternBlue;
  text-decoration: underline;
  text-underline-offset: 14px;
  text-decoration-thickness: 3px;
}

.SVGStyle {
  height: 20px;
  margin: 12px 0px;
}

.inputStyle {
  font-size: medium;
  background-color: $textBoxColor;
  width: 420px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  border: none;
}

.selectStyle {
  padding: 10px;
  background-color: $textBoxColor;
  font-size: medium;
  color: $ironsideGreyColor;
  border-radius: 5px;
  outline: none;
  overflow: hidden;
  border: none;
}

.logoStyle {
  background-color: $whiteColor;
  border: none;
  cursor: pointer;
}

.buttonStyle {
  padding: 12px 16px;
  font-size: 12px;
  background-color: $dashboardSecondaryColor;
  color: $whiteColor;
  font-weight: 800;
  cursor: pointer;
  border: none;
}

.borderTopStyle {
  border-top: 1px solid $whiteColor;
}

.imageStyle {
  height: 280px;
  width: 100%;
  object-fit: fit;
}

.plusButtonStyle {
  background-color: $dashboardModalColor;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.workspaceButton {
  width: 180px;
  padding: 12px;
  background-color: $lightModalColor;
  border-radius: 4px;
  @include flexDisplay(flex, center, center);
  cursor: pointer;
  color: $whiteColor;
  font-size: 12px;
  font-weight: 550;
  border: none;
  margin: 8px 0px;
}

.workspaceInput {
  padding: 10px;
  border-radius: 8px;
  font-size: 15px;
  width: 100%;
  outline: none;
  border: none;
}

.navigationStyle {
  padding: 24px 8px;
  height: 124px;
  background-color: $workspaceNavbarBackgroundColor;
}

.loginButton {
  width: 320px;
  color: $whiteColor;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.googleLogoButton {
  padding: 7px;
  background-color: $whiteColor;
  border: 3px solid $googleButtonColor;
  cursor: pointer;
  img {
    width: 22px;
    height: 22px;
  }
}

.avatarsContainer {
  @include flexDisplay(flex, space-between, center);
  img {
    border-radius: 50%;
    height: 32px;
    margin-right: 6px;
  }
  @media only screen and (max-width: screenSize.$screen-md) {
    img {
      height: 22px;
    }
  }
  @media only screen and (max-width: screenSize.$screen-sm) {
    img {
      height: 20px;
    }
  }
  @media only screen and (max-width: screenSize.$screen-xs) {
    img {
      height: 18px;
    }
  }
}

.postModalButton {
  width: 100%;
  padding: 8px;
  @include flexDisplay(flex, flex-start, center);
  cursor: pointer;
  background-color: transparent;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid $replyTextColor;
  box-shadow: 0px 1px $replyTextColor;

  img {
    height: 20px;
    margin-right: 4px;
  }
  span {
    font-size: 12px;
    color: $ironsideGreyColor;
  }
}

.commentSendButton {
  padding: 8px 16px;
  border: none;
  background-color: $dashboardModalColor;
  color: $whiteColor;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 550;

  @media only screen and (max-width: screenSize.$screen-md) {
    padding: 6px 12px;
    font-size: 10px;
  }
}

.locationPinStyle {
  margin-left: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  img {
    height: 24px;
  }
}

.commentAvatarStyle {
  img {
    border-radius: 50%;
    height: 32px;
    margin-right: 16px;
  }
}

.workspaceFileContent {
  @include flexDisplay(flex, space-between, align-items);
  flex-direction: column;
  background-color: $lightGreyColor;
  border: 3px solid $dashboardModalColor;
  width: 100%;
}

.workspaceFileHeader {
  @include flexDisplay(flex, space-between, flex-start);
  padding: 0px;
  background-color: transparent;
  border: none;
  height: 52px;
}

.workspaceHeaderButtons {
  @include flexDisplay(flex, space-between, flex-start);
  img {
    height: 20px;
    margin: 0px 4px;
    cursor: pointer;
  }
  button {
    border: none;
    background-color: transparent;
  }
}

.DesignBriefButton {
  margin: 0px 4px;
  padding: 5px 16px;
  margin-top: 1px;
  cursor: pointer;
  font-size: 8px;
  color: white;
  font-weight: 550;
  border-radius: 4px;
}

.paragraphTagStyle {
  color: $postVersionModalColor;
  font-size: 12px;
  font-weight: bold;
}
