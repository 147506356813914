@use 'src/Variable/_variable.scss' as colors;

.input-group-wrapper {
  width: 100%;
  height: 32px;
  gap: 12px;
}

.dropdown-input-container {
  height: 32px !important;
}

.email-input {
  width: 428px !important;
  border: 1px solid colors.$notesTextColor;
  border-radius: 4px;
  margin-right: 5px;
  overflow: hidden;

  &:global(.ant-select-focused) {
    border: 1px solid colors.$secondaryDashboardButtonColor;
  }
  :global(.ant-select-selector, input) {
    height: 100% !important;
    font-size: 11px;
    font-weight: 400;
    line-height: 25px; /* 127.273% */
    letter-spacing: 0.11px;
    color: colors.$blackColor;
  }
  :global(.ant-select-auto-complete) {
    padding-top: 5px !important;
  }

  :global(.ant-select-selector) {
    box-shadow: none !important;
    height: 100% !important;
    background: transparent !important;
    border: none !important;
    display: flex;
    align-items: center;

    :global(.ant-select-selection-placeholder) {
      overflow: hidden;
      color: colors.$silverChalice;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 0.11px;
    }
  }
}
