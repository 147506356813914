@use 'src/Variable/index' as styles;

.parent {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: styles.$alabaster;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 0px 16px 0px rgba(96, 97, 112, 0.12);
    border-radius: 8px;
    width: 100%;
    max-width: 416px;
    margin: auto;
    gap: 20px;
    background-color: styles.$whiteColor;

    form {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .logo-container {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 4px 2px 4px 2px;
      gap: 10px;

      .text-icon {
        margin-top: 4px;
      }
    }

    p {
      color: #666;
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 8px;
    }

    .email-input {
      width: 100%;
      margin-bottom: 12px;
      padding: 12px;
      background: styles.$whiteColor;
      border: 1px solid rgba(0, 0, 0, 0.08);
      border-radius: 8px;

      &::placeholder {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: styles.$silverChalice;
      }
    }

    .submit-button {
      cursor: pointer;
      padding: 8px 12px;
      border: 1px solid styles.$pacificBlue;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1.25px;
      color: styles.$pacificBlue;
      background-color: styles.$whiteColor;
      width: 100%;
    }

    .disabled {
      background-color: styles.$concreteColor;
      cursor: not-allowed;
    }

    .back-button {
      width: 100%;
      border: none;
      background-color: styles.$whiteColor;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.01em;
      text-decoration-line: underline;
      color: styles.$gray;
      cursor: pointer;
      margin-top: 4px;
    }

    .message {
      color: styles.$forestGreen;
    }

    .error {
      color: styles.$rossoCorsa;
    }
  }
}