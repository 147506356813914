@use '../../Variable' as styles;

.onboarding {
  @include styles.flexDisplay(flex, center, center);
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  height: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.onboarding-message {
  text-align: center;
  span {
    font-size: 20px;
  }
}
