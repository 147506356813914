.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .spin {
    position: absolute;
  }

  .image {
    position: relative;
  }
}
