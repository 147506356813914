@use 'src/Variable' as styles;

.category-title {
  display: flex;
  align-items: center;
  padding: 8px 14px;
  color: styles.$gray;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.category-option-container {
  padding: 2px 6px;

  .category-option {
    display: flex;
    height: 32px;
    padding: 6px 10px 6px 8px;
    border-radius: 6px;
    gap: 8px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: styles.$blackHaze;
    }

    .text-container {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        margin-bottom: 0;
      }

      .text {
        color: styles.$blackColor;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        word-break: break-word;

        &::first-letter {
          text-transform: capitalize;
        }
      }

      .date-range {
        color: styles.$gray;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .check-icon-container {
      display: flex;
      align-items: center;
      gap: 6px;

      color: styles.$gray;
      font-family: Outfit;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 160% */

      .check-icon {
        visibility: hidden;
        color: styles.$easternBlue;
      }

      .check-icon-selected {
        visibility: visible;
      }

      .arrow {
        color: styles.$nevada;
        transform: rotate(-90deg);
        width: 20px;
        height: 20px;
      }

      .up-arrow {
        transform: rotate( 90deg);
      }
    }
  }

  .category-option-selected {
    background: styles.$blackHaze;
  }
}

.category-footer {
  margin: 0 14px;
  border-bottom: 0.5px solid rgba(styles.$blackColor, 0.08);

  .show-more-btn {
    background: none;
    border: none;
    cursor: pointer;
    width: fit-content;
    color: styles.$easternBlue;
    margin-bottom: 8px;
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 145.455% */
  }
}

.remove-border {
  border-bottom: none;
}
