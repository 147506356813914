@use 'src/Variable/_variable.scss' as styles;

.kanban-board-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  :global(.ant-select-selector) {
    margin-bottom: 0 !important;
  }
}

.last-updated {
  color: styles.$carbonGrey;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.sync-comments {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 6px 12px;
  margin-left: 8px;
  margin-right: 16px;
  border-radius: 8px;
  background: rgba(styles.$pastelGreen, 0.08);
  color: styles.$deepSeaGreen;
  font-size: 14px;
  font-weight: 400;

  :global(.ant-spin-dot-item) {
    background-color: styles.$deepSeaGreen !important;
  }
}

.files-button {
  display: flex;
  height: 34px;
  gap: 10px;
  padding: 8px 12px;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;

  .disabled-import-icon {
    path {
      stroke: styles.$silverDark;
    }
  }
}
