@use 'src/Variable' as colors;

.color__paletteCardWrapper {
  position: relative;
  width: 270px;
  height: 220px;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
  overflow: hidden;
  &:hover:not(.readonly_color__paletteCardWrapper) {
    .action__buttonWrapper {
      display: flex;
    }
    .color__codeText {
      display: none;
    }
  }
}

.color__container {
  height: 182px;
}

.color__paletteCardFooter {
  height: 38px;
  background: colors.$linkWaterColor;
  text-align: center;
}

.color__codeText {
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: colors.$whiteColor;
}

.action__buttonWrapper {
  display: none;
}

.color__actionButton {
  width: 86px;
  height: 29px;

  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  padding: 0;

  background: colors.$lochinvarColor;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0 0.5px 2px rgba(96, 97, 112, 0.16);
  border: 1px solid colors.$lochinvarColor;
  border-radius: 12px;

  &:hover {
    color: colors.$lochinvarColor;
    background: colors.$whiteColor;
    border: 1px solid colors.$lochinvarColor;
  }
}

.color__paletteInput {
  $margin-left: 16px;

  width: calc(100% - 2 * $margin-left);
  margin: 8px $margin-left;
  border: none;

  ::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: colors.$notesTextColor;
  }
}

.sketch__pickerInput {
  position: absolute;
  top: -50%;
  left: 50%;
  z-index: 100;
  overflow: visible;
}
