@use 'Variable/_variable.scss' as colors;

.form-item {
  &,
  & *:not(:global(.sketch-picker)):not(:global(.sketch-picker *)):not(:global(.ant-select-arrow)):not(:global(.ant-select-selection-placeholder)) {
    display: inline;
    color: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
  }

  :global(.ant-form-item-control-input) + div,
  :global(.ant-form-item-explain) {
    display: none !important;
  }

  &:global(.ant-form-item-has-error) {
    .input-text,
    input::placeholder,
    .input-text::before {
      color: colors.$errorColor !important;
      text-decoration-color: colors.$errorColor;
    }
  }

  &[disabled] .input-text {
    text-decoration: none;
  }
}

.input-text {
  color: inherit;
  font-size: inherit !important;
  font-weight: inherit;
  line-height: inherit !important;
  letter-spacing: inherit !important;

  word-break: break-word;

  &:focus {
    text-decoration-color: colors.$easternBlue;
  }

  &[data-prefix]:before {
    content: attr(data-prefix);
  }

  &:empty {
    color: colors.$silverColor !important;
    &:before {
      content: attr(data-placeholder);
      cursor: text;
      font-weight: 500;
      color: colors.$cloudColor;
    }
    &:focus:before {
      border-left: 1px solid black;
      margin-left: -1px;
    }
  }
}

.field-li {
  display: flex;
  align-items: center;

  &:before {
    content: '•';
    margin-right: 10px;
    font-size: 2rem;
  }
}
