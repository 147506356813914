@use 'src/Variable/_variable.scss' as colors;

.add-file-button {
  $text-color: colors.$whiteColor;
  $bg-color: rgba(48, 140, 143, 0.8);

  height: auto;
  color: $text-color;
  background: $bg-color;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;

  &:hover,
  &:active,
  &:focus {
    color: $bg-color;
    background: $text-color;
    border-color: $bg-color;
  }
}

.dropdown-button-icon {
  width: 24px;
  height: 24px;

  &.image-icon {
    width: 12px;
    height: 12px;
  }
}

.figma-icon {
  background: colors.$whiteColor;
  border-radius: 100%;
  padding: 3px;
}

.add-figma-file-modal {
  background: colors.$linkWaterColor;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 12px;

  :global(.ant-input-prefix) {
    padding: 0 5px !important;
  }
  :global(.ant-modal-content),
  :global(.ant-modal-header) {
    background: transparent;
    padding: 0;
  }
}
