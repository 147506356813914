@use 'Variable/_variable.scss' as colors;

:export {
  textareaBackgroundColor: colors.$linkWaterColor,
}

.brand-personality-item {
  padding: 10px 22px 10px 24px;
  border: 1px solid colors.$easternBlue;
  border-radius: 50px;
  line-height: 16px;

  &.selected {
    color: colors.$whiteColor;
    background-color: colors.$easternBlue;
  }
}

.create-new-tag-form {
  width: 288px;
  background: colors.$linkWaterColor;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
}
