@use 'src/Variable/index' as styles;

.settings-wrapper {
  background: styles.$alabasterColor;
  height: 100vh;
  overflow: auto;
  padding-bottom: 50px;
}

.settings-heading {
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.25px;
  color: styles.$projectThumbnailColor;
}

.settings-content {
  @include styles.flexDisplay(flex, space-between, inherit);
  flex-direction: column;
}

.container {
  max-width: 1156px;
  padding: 0 16px;
  margin: 0 auto;
}
