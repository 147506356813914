@use 'src/Variable/variable' as colors;

.input {
  &:hover,
  &:focus {
    border-color: colors.$notesTextColor !important;
    box-shadow: none;
  }

  img {
    width: 16px;
    height: 20px;
    padding-left: 0px;
  }
}

.button {
  height: 40px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  letter-spacing: 1.25px;
  border-radius: 12px;
}
