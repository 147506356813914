@use 'src/Variable' as styles;
@use 'src/styles/rem' as util;


.add-figma-file-modal {
  background: styles.$linkWaterColor;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  border-radius: 12px;

  :global(.ant-input-prefix) {
    padding: 0 5px !important;
  }
  :global(.ant-modal-content),
  :global(.ant-modal-header) {
    background: transparent;
    padding: 0;
  }
}

.add-file-button {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-top: 32px;
  height: 36px;
}

.main-heading {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 16px;
}

.description {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  opacity: 0.5;
  margin-top: 12px;
}

.feed__heading {
  font-size: util.px-to-rem(20px);
  font-weight: 500;
  line-height: util.px-to-rem(30px);
  color: styles.$notesTextColor;
}

.feed__timeline {
  margin-top: util.px-to-rem(24px);
  color: styles.$notesTextColor;
  letter-spacing: 1.5px;
  font-weight: 600;
  font-size: util.px-to-rem(12px);
  margin-bottom: util.px-to-rem(20px);
}

.workspace__allThumbnails {
  text-align: center;
}
.empty-file-container {
  margin-top: 173px;
}

.workspace__timeline {
  margin-left: util.px-to-rem(40px);
  color: styles.$notesTextColor;
  letter-spacing: 1.5px;
  font-weight: 600;
  font-size: util.px-to-rem(12px);
  margin-bottom: util.px-to-rem(20px);
}

.workspace__notifications {
  margin-left: util.px-to-rem(12px);
  margin-bottom: util.px-to-rem(32px);
  margin-top: util.px-to-rem(20px);
}
