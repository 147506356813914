// Usage className="m{direction}-{size}"
// Example: className="mt-xs"; className="mx-sm-md" (x, y - axises)
// Example: className="mt-xs-on-md-screen"

@use "sass:map";

@use './screen_size.scss' as screenSize;
@use './z_index.scss' as zIndexSize;

$sizes: (
  zero: 0px,
  xxxs: 1px,
  xxs: 3px,
  xs: 5px,
  xs-sm: 7px,
  sm: 10px,
  sm-md: 15px,
  md: 20px,
  small-md-lg: 25px,
  md-lg: 30px,
  lg: 40px,
  xlg: 50px,
  xxl: 60px,
  xxl-large: 70px,
  xxl-huge: 80px,
  huge: 100px,
);

$i: 4;
@while $i < 70 {
  $sizes: map.set($sizes, $i, $i + px);
  $i: $i + 4;
}

$directions: (
  t: "top",
  b: "bottom",
  r: "right",
  l: "left",
);

@mixin createScreenSpacing($spacingPrefix, $args...) {
  @each $screenSize, $screenSizeValue in screenSize.$screen-sizes {
    .#{$spacingPrefix}-on-#{$screenSize}-screen {
      @media(max-width: $screenSizeValue) {
        @content;
      }
    }
  }
}

@each $direction, $directionValue in $directions {
  @each $size, $sizeValue in $sizes {
    .m#{$direction}-#{$size} {
      margin-#{$directionValue}: $sizeValue;
    }
    .invert-m#{$direction}-#{$size} {
      margin-#{$directionValue}: -($sizeValue);
    }
    .p#{$direction}-#{$size} {
      padding-#{$directionValue}: $sizeValue;
    }
    .m#{$direction}-#{$size}-important {
      margin-#{$directionValue}: $sizeValue !important;
    }
    .invert-m#{$direction}-#{$size}-important {
      margin-#{$directionValue}: -($sizeValue) !important;
    }
    .p#{$direction}-#{$size}-important {
      padding-#{$directionValue}: $sizeValue !important;
    }

    @include createScreenSpacing('m' + direction + '-' + size) {
      margin-#{$directionValue}: $sizeValue;
    }
    @include createScreenSpacing('p' + direction + '-' + size) {
      padding-#{$directionValue}: $sizeValue;
    }
  }
}

@each $size, $sizeValue in $sizes {
  .mx-#{$size} {
    margin-left: $sizeValue;
    margin-right: $sizeValue;
  }
  .invert-mx-#{$size} {
    margin-left: -($sizeValue);
    margin-right: -($sizeValue);
  }
  .px-#{$size} {
    padding-left: $sizeValue;
    padding-right: $sizeValue;
  }
  .my-#{$size} {
    margin-top: $sizeValue;
    margin-bottom: $sizeValue;
  }
  .invert-my-#{$size} {
    margin-top: -($sizeValue);
    margin-bottom: -($sizeValue);
  }
  .py-#{$size} {
    padding-top: $sizeValue;
    padding-bottom: $sizeValue;
  }
  .mx-#{$size}-important {
    margin-left: $sizeValue !important;
    margin-right: $sizeValue !important;
  }
  .invert-mx-#{$size}-important {
    margin-left: -($sizeValue) !important;
    margin-right: -($sizeValue) !important;
  }
  .px-#{$size}-important {
    padding-left: $sizeValue !important;
    padding-right: $sizeValue !important;
  }
  .my-#{$size}-important {
    margin-top: $sizeValue !important;
    margin-bottom: $sizeValue !important;
  }
  .invert-my-#{$size}-important {
    margin-top: -($sizeValue) !important;
    margin-bottom: -($sizeValue) !important;
  }
  .py-#{$size}-important {
    padding-top: $sizeValue !important;
    padding-bottom: $sizeValue !important;
  }

  @include createScreenSpacing('mx' + '-' + size) {
    margin-left: $sizeValue;
    margin-right: $sizeValue;
  }
  @include createScreenSpacing('px' + '-' + size) {
    padding-left: $sizeValue;
    padding-right: $sizeValue;
  }

  @include createScreenSpacing('my' + '-' + size) {
    margin-top: $sizeValue;
    margin-bottom: $sizeValue;
  }
  @include createScreenSpacing('py' + '-' + size) {
    padding-top: $sizeValue;
    padding-bottom: $sizeValue;
  }
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}
.mx-auto-important {
  margin-right: auto !important;
  margin-left: auto !important;
}

.m-zero {
  margin: 0;
}
.m-zero-important {
  margin: 0 !important;
}

.p-zero {
  padding: 0;
}
.p-zero-important {
  padding: 0 !important;
}

@include createScreenSpacing('mx-auto') {
  margin-right: auto;
  margin-left: auto;
}

@include createScreenSpacing('m-zero') {
  margin: 0;
}

@include createScreenSpacing('p-zero') {
  margin: 0;
}
