@use 'Variable/_variable.scss' as colors;

.dropdown-button {
  background: none;
  border: none;
  min-width: 132px;
  color: colors.$blackColor;

  &[disabled] {
    cursor: auto;
  }
  &:not([disabled]):hover {
    background: colors.$cloudyBlue;
    color: colors.$blackColor;
  }
}
