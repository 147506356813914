@use 'Variable/_variable.scss' as colors;

.first-view-body-wrapper {
  background: colors.$linkWaterColor;
  border-radius: 12px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  height: 400px;
}

.first-view-body-text {
  color: colors.$notificationDateColor;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
