@use 'Variable/_variable.scss' as colors;

.add-first-moodboard-item-wrapper {
  height: 100%;
  background: colors.$milkWhite;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
  border-radius: 12px;
}

.milestone-icon-wrapper {
  position: relative;
}

.milestone-icon {
  border-radius: 12px;
}

.milestone-star-icon {
  position: absolute;
  top: -21px;
  right: -25px;
}
