@use 'Variable/_variable.scss' as colors;
@import 'Components/questionnaireComponents/styles.module.scss';

.select-wrapper {
  margin-bottom: 0;
  max-width: 100%;

  display: inline-block;
  border: 1px solid colors.$lavenderPinocchio;
  border-radius: 4px;
  padding: 4px 8px;

  &:focus-within {
    border-color: colors.$easternBlue;
  }

  &:global(.ant-form-item-has-error) {
    border-color: colors.$errorColor;
  }

  &,
  :global(.ant-select-selector) {
    display: inline-block;
  }

  &,
  & *:not(:global(.ant-select-arrow)):not(:global(.ant-select-selection-placeholder)) {
    color: inherit !important;
    font-size: inherit !important;
    line-height: 20px !important;
    letter-spacing: inherit !important;
  }
  :global(.ant-select-selection-placeholder) {
    line-height: 20px !important;
  }

  &:global(.ant-form-item) {
    vertical-align: initial;
  }
  :global(.ant-form-item-control) {
    display: inline-flex;
  }
  :global(.ant-form-item-control-input) {
    display: inline-flex;
    min-height: auto;
  }
  :global(.ant-form-item-explain) {
    display: none;
  }

  &:global(.ant-form-item-has-error) {
    .selected-option,
    :global(.ant-select-selection-placeholder) {
      color: colors.$errorColor !important;
      text-decoration-color: colors.$errorColor !important;
    }
  }
}

.select {
  height: auto !important;
  width: auto !important;
  max-width: 100%;
  position: relative;
  display: inline-block;

  :global(.ant-select-selector) {
    margin-bottom: 0;
    padding: 0 !important;
    background-color: transparent !important;
    height: auto !important;
    width: auto !important;
    box-shadow: none !important;
    max-width: 100%;

    &:after {
      content: none;
    }
  }

  &:not(:global(.ant-select-disabled)) :global(.ant-select-selection-item) {
    @extend .input-text;
  }

  :global(.ant-select-selection-item) {
    font-weight: inherit;
  }

  :global(.ant-select-selection-overflow) {
    padding: 0 !important;

    :global(.ant-select-selection-search) {
      display: none !important;
    }

    :global(.ant-select-selection-overflow-item):not(:nth-last-child(-n+2)) {
      .selected-option {
        &:after {
          content: ',';
          padding-right: 0.3em;
        }
      }
    }
    :global(.ant-select-selection-overflow-item):nth-last-child(-n+2) {
      .selected-option {
        &:before {
          content: 'and';
          padding-right: 0.3em;
        }
      }
    }

    // 1 option selected
    :global(.ant-select-selection-overflow-item):first-child:nth-last-child(2) {
      .selected-option {
        &:before {
          content: '';
        }
      }
    }

    // 2 options selected
    :global(.ant-select-selection-overflow-item):first-child:nth-last-child(3) {
      .selected-option {
        &:after {
          content: '';
        }
      }
    }
  }

  :global(.ant-select-selection-placeholder) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    transform: none;
    overflow: inherit;
    color: colors.$silverColor !important;
  }
  &:not(:global(.ant-select-disabled)) :global(.ant-select-selection-placeholder) {
    @extend .input-text;
  }

  :global(.ant-select-selection-placeholder),
  :global(.ant-select-selection-item) {
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
  }

  :global(.ant-select-arrow) {
    color: colors.$pacificBlueColor !important;
    stroke: colors.$pacificBlueColor;

    svg {
      visibility: hidden;
    }

    &:after {
      content: '';
      min-width: 125%;
      height: 110%;
      background-image: url('~assets/dropdown_arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
    }
  }

  &:global(.ant-select-multiple) {
    margin-right: 0;
    &:not(:global(.ant-select-disabled)) :global(.ant-select-selector) {
      padding-right: 15px !important;
    }
    :global(.ant-select-arrow) {
      right: 0.2em;
    }
  }
  &:global(.ant-select-single) :global(.ant-select-arrow) {
    right: 0.3em;
  }
}
