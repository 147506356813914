@use 'Variable' as colors;
@use 'styles/z_index.scss' as zIndexSize;

$workspace-sidebar-card-width: 265px;
$milestone-name-width: 180px;

.workspace__sidebarCard {
  min-width: $workspace-sidebar-card-width;

  &.active__sidebarCard {
    background: colors.$whiteSmoke;
  }
}

.milestone__name{
  max-width: $milestone-name-width;
}
