@use 'src/Variable' as styles;

.search-auto-complete {
  :global(.ant-select-dropdown) {
    width: auto !important;
    border-radius: 8px;

    :global(.ant-select-item-group) {
      color: styles.$carbonGrey;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    :global(.ant-select-item-option) {
      padding-left: 12px;
    }

    :global(.ant-select-item-option-content) {
      color: styles.$blackColor;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.search-bar {
  display: flex;
  align-items: center;
  width: 300px !important;
  padding: 8px 16px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid styles.$lavenderPinocchio;
  background: styles.$whiteColor;

  &:hover {
    background: styles.$whiteColor;
    border: 1px solid styles.$lavenderPinocchio;
  }

  img {
    filter: opacity(0.5);
  }

  :global(.ant-input-prefix) {
    margin-left: 12px;
    margin-right: 8px;
  }

  :global(.ant-input) {
    color: styles.$blackColor;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  :global(.ant-input-suffix) {
    margin-right: 16px;
  }

  :global(.anticon anticon-close-circle) {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
