@use 'src/Variable' as styles;

.dropdown__wrapper {
  margin-top: 32px;
  :global(.ant-dropdown) {
    min-width: 0 !important;
  }
}

.milestone__name {
  font-size: 24px;
  letter-spacing: 0.25px;
  color: styles.$projectThumbnailColor;
}

.remove__button {
  padding: 8px !important;
  &:hover {
    color: styles.$whiteColor;
    background: styles.$sunsetOrangeColor !important;
  }
}

.edit__button {
  border-radius: 1000px;
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    border-color: styles.$pacificBlueColor;
  }

  color: styles.$pacificBlueColor;

  &:hover {
    background-color: styles.$pacificBlueColor;
    color: styles.$whiteColor;
    svg path {
      fill: styles.$whiteColor;
    }
  }
}
