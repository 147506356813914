@use 'src/Variable/_variable.scss' as colors;
@use 'src/styles/z_index.scss' as zIndexSize;

$minHeight: calc(100vh - 380px);
$imageContainerHeight: calc(100vh - 330px);

:export {
  canvasBrushColor: colors.$pacificBlueColor,
}

.image-container {
  background-color: colors.$commentFeedLineColor;
  border-radius: 8px;
  flex: 1 1 auto;
  min-height: $minHeight;
  height: $imageContainerHeight;

  &.comments-loading,
  &.show-data,
  &.check-figma-file-old,
  &.new-figma-version-loading,
  &.save-annotation-in-progress,
  &.is-load-image-from-g-drive {
    position: relative;

    &:after {
      padding: 10px;
      background-color: colors.$silverColor;
      color: colors.$whiteColor;
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 10px;
    }
  }
  &.comments-loading:after {
    content: 'Comments Loading ...';
  }
  &.check-figma-file-old:after {
    content: 'Checking Figma File ...';
  }
  &.new-figma-version-loading:after {
    content: 'Loading New Figma File Version ...';
  }
  &.save-annotation-in-progress:after {
    content: 'Annotation saving ...';
  }
  &.is-load-image-from-g-drive:after {
    content: 'Processing ...';
  }
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .image {
    max-width: 100%;
    max-height: 100%;
  }

  &.is-comment-mod,
  .comment-cursor {
    cursor: url('~assets/comment_marker.svg') 0 45, auto;
  }
  .draw-cursor {
    cursor: url('~assets/canvas_pencil.svg') 0 31, auto;
  }

  :global(.ant-dropdown) {
    @extend .show-tools-on-hover;
  }

  &:hover {
    .show-tools-on-hover,
    :global(.ant-dropdown) {
      opacity: 1;
    }
  }
}

.is-thumbnail {
  .image-wrapper {
    height: 115px;
    width: 215px;
  }
  border: 1px solid colors.$cloudColor;
  border-radius: 5px;
  background-color: colors.$greenWhiteColor;
  width: 216px;
}

.file-image-wrapper {
  position: relative;
  height: 100%;
}

.transform-component-wrapper {
  width: 100% !important;
  height: 100% !important;
  min-height: $minHeight;
  background: colors.$aquaSqueeze;
}

.image {
  max-width: 100%;
  &:not(.full-screen) {
    max-height: $minHeight;
  }
  &.full-screen {
    height: 100%;
  }
}

.bottom-zoom-tools-wrapper {
  position: absolute;
  border-radius: 4px;
  overflow: hidden;
  right: 20px;

  a,
  button {
    border: none;
    background-color: rgba(colors.$blackColor, 0.65);
    width: 40px;
    height: 40px;
    transition: all 0.3s;

    &[disabled] {
      cursor: auto;
    }
    &:not([disabled]):hover {
      background-color: colors.$blackColor;
      color: colors.$whiteColor;
    }
  }
}
.bottom-zoom-tools-wrapper {
  bottom: 20px;
}

.full-screen {
  height: 100%;
}

.show-tools-on-hover {
  opacity: 0;
  transition: opacity 0.4s;
}

.test-point {
  width: 1px;
  height: 1px;
  position: absolute;
  background-color: red;
}

.vote-tools {
  position: absolute;
  z-index: zIndexSize.$third-layer;
  top: 16px;
  left: 20px;
}

.annotation-tools {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.annotation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent !important;

  :global(#react-sketch-canvas__canvas-background-group) {
    display: none;
  }
}

.center-icon {
  width: 16px;
  height: 16px;
  overflow: visible;
  box-sizing: content-box;
  display: inline-block;
  vertical-align: -0.125em;
}
