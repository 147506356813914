@use 'Variable' as styles;

@use 'src/Components/DashboardSidebar/styles.module.scss' as *;

.project__details {
  width: 100%;
}

.workspace__body {
  @include styles.flexDisplay(flex, inherit, inherit);
  flex-direction: column;
  flex: 1;
  transition: .25s ease-in-out;
}

.sidebar__Wrapper {
  transition: .25s ease-in-out;
}

.sidebar__out {
  position: absolute;
  transition: .25s ease-in-out;
  left: -$sidebar-width;
}

.left__margin {
  margin-left: $sidebar-width;
}
