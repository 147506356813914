@use 'src/Variable' as styles;

$popover-width: 536px;

.select-file-container {
  display: flex;
  padding: 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid styles.$lavenderPinocchio;
  background: styles.$whiteColor;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.select-file-popover {
  :global(.ant-popover) {
    width: $popover-width;
  }

  :global(.ant-popover-inner) {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .file-option-container {
      display: flex;
      flex-direction: column;
      padding: 2px 6px;
      align-items: center;
      align-self: stretch;
      width: 536px;

      .file-option {
        display: flex;
        padding: 6px 10px 6px 8px;
        align-items: center;
        gap: 8px;
        width: 100%;
        cursor: pointer;
        border-radius: 6px;
        &:hover {
          background: styles.$blackHaze;
        }

        .file-option-title {
          color: styles.$blackColor;
          font-size: 16px;
          font-weight: 400;
          line-height: 160%; /* 25.6px */
        }
      }

      .sub-options-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 4px;
      }
    }
  }

  :global(.ant-popover-inner-content) {
    padding: 0;
  }
}

.add-file-title {
  color: styles.$blackColor;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25px;
}

.add-file-description {
  color: styles.$blackColor;
  align-self: stretch;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.6;
}

.select-file-button {
  width: 100%;
  margin-top: 24px;
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  align-self: stretch;
  height: 40px;

  .button-icon {
    width: 16px;
    height: 16px;
  }

  .button-text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
}

.imported-files-title {
  color: styles.$blackColor;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
}

.imported-files-description {
  color: styles.$blackColor;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.6;
  margin-bottom: 24px;
}

.all-imported-files-container {
  overflow-y: auto;
  max-height: 320px;
}

.added-figma-file-wrapper {
  display: flex;
  padding: 6px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 2px solid rgba(styles.$blackColor, 0.08);
  border-radius: 8px;
  margin-top: 16px;
  opacity: 0.8;

  .figma-link {
    text-transform: none;
    color: styles.$blackColor;
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
      color: styles.$blackColor;
    }
  }

  .external-link-icon {
    width: 14px;
    height: 16px;
    margin-left: 8px;
    color: styles.$silverGray;
  }

  .link-text {
    color: styles.$blackColor;
    font-size: 16px;
    font-weight: 400;
    line-height: 35px;
  }

  .switch {
    margin-right: 12px;
    min-width: 32px;
  }

  .trash-icon {
    cursor: pointer;
  }
}
