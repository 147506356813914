@use 'src/Variable' as styles;

.toggle__switch {
  background: styles.$easternBlue;
}

.status {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: styles.$notificationDateColor;
}
