@use 'src/Variable' as styles;

$col-title-border-radius: 176px;

$kanban-colors: (
  one: (
    background-color: styles.$vistaWhite,
    border-color: styles.$oysterPink,
    title-background: styles.$oysterPink,
    background-image: url('~assets/images/kanban_board/to_do.svg')
  ),
  two: (
    background-color: styles.$selago,
    border-color: styles.$spindle,
    title-background: styles.$thinBlue,
    background-image: url('~assets/images/kanban_board/in_progress.svg')
  ),
  three: (
    background-color: styles.$blackSqueeze,
    border-color: styles.$regentBlue,
    title-background: styles.$deepGreen,
    background-image: url('~assets/images/kanban_board/done.svg')
  ),
  four: (
    background-color: styles.$porcelainLight,
    border-color: styles.$iron,
    title-background: styles.$backLogTitleBackground,
    background-image: url('~assets/images/kanban_board/backlog.svg')
  )
);

.droppable-col {
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  overflow: auto;
  height: calc(100vh - 156px);
  min-width: 320px;
}

.col-name-container {
  gap: 8px;

  :global(.ant-input:focus) {
    box-shadow: none !important;
    border: 1px solid styles.$wedgeWood !important;
  }

  :global(.ant-input:hover) {
    border-color: styles.$wedgeWood !important;
  }

  .add-button {
    border: none;
    background: none;
    color: styles.$blackColor;
    opacity: 0.30;
    font-size: 16px;
    font-weight: 400;
    margin-right: 12px;
    cursor: pointer;
  }

  .add-button-disabled {
    cursor: not-allowed;
  }
}

.task-container {
  overflow: auto;
  padding-right: 8px;
  height: calc(100vh - 213px);

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: styles.$silverChalice;
    border-radius: 8px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: styles.$gray;
  }
}

@each $col, $col-props in $kanban-colors {
  .#{$col} {
    background: map-get($col-props, background-color);
    border-color: map-get($col-props, border-color);
    background-image: map-get($col-props, background-image);

    &-draggingOver {
      background: map-get($col-props, border-color);
    }

    &-title {
      background: map-get($col-props, title-background);
    }
  }
}

.col-title {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  border-radius: $col-title-border-radius;
  width: fit-content;
  cursor: pointer;
  gap: 6px;

  font-family: Outfit;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  .col-name {
    font-size: 14px;
    color: styles.$blackColor;
  }

  .col-items {
    font-size: 12px;
    color: styles.$nevada;
  }
}

.col-title-input {
  height: fit-content !important;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid styles.$wedgeWood;
  background-color: styles.$whiteColor !important;
}

.option-button {
  visibility: hidden;
  border: none;
  cursor: pointer;
  background: transparent;
  display: flex;

  img {
    filter: opacity(0.5);
  }
}

.show-option-button {
  visibility: visible;
}

.add-new-task {
  margin-top: 14px;
  margin-left: 4px;
  font-weight: 400;
  width: fit-content;
  color: styles.$silverChalice;
  cursor: pointer;
  display: flex;
  align-items: center;

  .plus-icon {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    margin-right: 4px;
  }

  .new-button {
    font-size: 14px;
    line-height: 20px;
  }
}

.col-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid styles.$mercuryLight;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16), 0 0 2px 0 rgba(40, 41, 61, 0.04);

  :global(.ant-dropdown-menu-item) {
    padding: 0 8px;
  }
}

.sort-option-menu {
  @extend .col-menu;
  width: 241px;

  :global(.ant-dropdown-menu) {
    width: 100%;
  }
}

.menu-button {
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background: transparent;
  width: 100%;
  height: 37px;
  font-weight: 400;
  font-size: 16px;
  color: styles.$blackColor;
}

.sort-option {
  color: rgba(styles.$sortOptionColor, 0.50);
  padding-left: 4px;
}

.sort-option-button {
  @extend .menu-button;
  justify-content: space-between;
}
