@use 'src/Variable' as styles;

.name__wrapper {
  position: relative;
  width: 100% !important;
  overflow: hidden;
  :global(.ant-form-item-explain) {
    position: absolute;
  }
}

.name__input {
  font-weight: 500;
  height: 30px !important;
  align-items: center;
  text-align: center;
  width: 100% !important;
  font-size: 20px !important;
  border: none !important;
  color: styles.$projectThumbnailColor !important;
  letter-spacing: 0.25px !important;
  border-bottom: 1px solid !important;
  border-color: styles.$primaryButtonBorderColor !important;
  border-radius: 0 !important;
  padding: 8px 0px !important;
  line-height: 30px;
  &::placeholder {
    font-size: 20px !important;
    color: rgba(0, 0, 0, 0.16);
    letter-spacing: 2.25px
  }

  &:focus {
    box-shadow: none !important;
  }
}
