@use 'src/Variable' as styles;

.assignee-dropdown-container {
  display: flex;
  z-index: 100;
  min-width: 320px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 4px;
  background: styles.$whiteColor;
  box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04);

  .input-container {
    display: flex;
    background: styles.$alabaster;
    padding: 8px 10px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    width: 100%;
    gap: 8px;

    .search-bar-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 32px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid styles.$lavenderPinocchio;
      background: styles.$whiteColor;

      img {
        margin-left: 10px;
        filter: opacity(0.5);
      }

      input {
        width: 100%;
        border: none !important;
        color: styles.$blackColor !important;
        font-weight: 400 !important;
        letter-spacing: 0.5px !important;
      }

      &:hover,
      &:focus {
        outline: none;
        border: 1px solid styles.$pacificBlueColor !important;
        border-left: unset;
        box-shadow: none;
      }
    }
  }

  .assignees-container {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    width: 100%;
    gap: 4px;
    max-height: 150px;
    overflow-y: auto;

    .secondary-heading {
      color: styles.$gray;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      margin-bottom: 4px;
      margin-top: 8px;
      display: flex;
    }

    .no-result {
      font-size: 14px;
      color: styles.$torchRed;
      margin-left: 8px;
    }

    .category-title {
      color: styles.$gray;
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }

  .invite-box {
    display: flex;
    width: 100%;
    overflow: auto;
    padding: 8px 14px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-top: 0.5px solid rgba(0, 0, 0, 0.08);
    background: styles.$blackHaze;

    .invite-top {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .close-icon {
        color: styles.$gray;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }

      .heading {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
        letter-spacing: 0.13px;
      }
    }
  }

  .dropdown-footer {
    display: flex;
    height: 48px;
    padding: 8px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-top: 0.5px solid rgba(styles.$blackColor, 0.08);

    .invite-team {
      display: flex;
      align-items: center;
      color: styles.$pacificBlueColor;
      font-variant-numeric: lining-nums tabular-nums;
      font-family: Outfit;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 153.846% */
      letter-spacing: 0.25px;
    }
  }
}
