.modal {
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
  padding: 0;

  :global(.ant-modal-header) {
    border: none;
    padding: 21px 0 16px 16px;
  }

  &,
  :global(.ant-modal-content),
  :global(.ant-modal-header) {
    border-radius: 12px;
  }
}
